import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import buildLink from 'services/buildLink';
import { keys, map, values } from 'lodash';
import { ChevronRight } from '@sumup/icons';
import { Body } from '@sumup/circuit-ui';
import {
  PATHS,
  NAVIGATION_CARDS_MAX_ROWS_COUNT,
  CLIENT_ONBOARD_STATUSES,
  NON_ONBOARDED_MERCHANT_ROUTES,
} from 'variables';
import restrictRoutes from 'services/restrictRoutes';
import ClientDataContext from 'context/clientData';
import AuthContext from 'context/auth';
import formatBalance from 'services/formatBalance';
import {
  Content,
  ContentColumn,
  NavigationCardHeading,
  StyledNavigationCards,
} from './NavigationCardsStyled';
import {
  SectionLink,
  StyledCard,
  StyledCardIcon,
  ColorCircle,
  ModuleTitle,
} from '../../MerchantNavigationStyled';

const NavigationCards = ({ clientId, onboarded, modules }) => {
  const {
    state: { permissions },
  } = useContext(AuthContext);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);
  const { currency: { exponent, code: currencyCode } = {} } = clientData;

  const formatModuleBalance = (module) =>
    formatBalance({
      balance:
        module.balance.negative_balance * -1 + module.balance.balance || 0,
      exponent,
      currencyCode,
    });

  const renderContent = (sections) => (
    <ContentColumn>
      {map(sections, (section) => (
        <SectionLink
          key={section.title}
          disabled={
            !NON_ONBOARDED_MERCHANT_ROUTES(clientData)[section.link] &&
            onboarded !== CLIENT_ONBOARD_STATUSES.ONBOARDED
          }
          to={buildLink([PATHS.client, clientId, section.link])}
        >
          <Body noMargin size="two" variant="highlight">
            {section.title}
          </Body>
          <ChevronRight />
        </SectionLink>
      ))}
    </ContentColumn>
  );

  return (
    <StyledNavigationCards>
      {map(keys(modules), (appName) => (
        <StyledCard key={appName}>
          <NavigationCardHeading bold noMargin>
            <StyledCardIcon>
              {modules[appName].image || (
                <ColorCircle
                  className={`module-color-${modules[appName].color}`}
                />
              )}
            </StyledCardIcon>
            <ModuleTitle size="one" noMargin>
              {modules[appName].title}
            </ModuleTitle>
            {modules[appName].balance && (
              <Body size="two" noMargin>
                {formatModuleBalance(modules[appName])}
              </Body>
            )}
          </NavigationCardHeading>
          <Content>
            {renderContent(
              values(
                restrictRoutes({
                  appName: modules[appName],
                  status: onboarded,
                  permissions,
                  clientId,
                })
              ).splice(0, NAVIGATION_CARDS_MAX_ROWS_COUNT)
            )}
            {renderContent(
              values(
                restrictRoutes({
                  appName: modules[appName],
                  status: onboarded,
                  permissions,
                  clientId,
                })
              ).splice(NAVIGATION_CARDS_MAX_ROWS_COUNT)
            )}
          </Content>
        </StyledCard>
      ))}
    </StyledNavigationCards>
  );
};

NavigationCards.defaultProps = {
  onboarded: '',
};

NavigationCards.propTypes = {
  clientId: PropTypes.string.isRequired,
  onboarded: PropTypes.string,
  modules: PropTypes.object.isRequired,
};

export default NavigationCards;
