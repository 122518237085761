import {
  getUserDetails,
  getClient,
  getMerchantAccountStatus,
  getAccountById,
  getSubbalanceClient,
} from 'api';
import {
  getIbanProduct,
  getIbanProductFromAccount,
  isSubBalance,
  fetchGasAccounts,
} from './globalAccountsService';

const handleResult = (
  [
    { value: userDetails },
    { value: gasAccounts },
    { value: merchantAccountResponse },
    { value: clientData },
    { value: accountData },
  ],
  accountId
) => {
  if (!gasAccounts && accountId) {
    throw Error('Client data is required for subbalance.');
  }

  const client = { ...clientData?.data };

  if (gasAccounts) {
    const ibanProduct = accountId
      ? getIbanProductFromAccount(gasAccounts, accountId)
      : getIbanProduct(gasAccounts);

    if (ibanProduct) {
      client.iban = ibanProduct.iban;
      client.bank_account_issue_date = ibanProduct.created_at;
    }
  }

  const externalLinks = accountData?.data?.external_links || {};
  const merchantAccountStatus =
    merchantAccountResponse?.data?.merchant_account_status;

  return {
    ...(userDetails || {}),
    data: {
      ...(userDetails?.data || {}),
      ...client,
      merchantAccountStatus,
      gasAccounts,
    },
    externalLinks,
  };
};

export const getMerchantPageData = async (
  httpClient,
  { clientId, accountId }
) => {
  const requests = [
    getUserDetails({ clientId, httpClient }),
    fetchGasAccounts(httpClient, clientId),
    getMerchantAccountStatus({ merchantCode: clientId, httpClient }),
  ];

  const clientRequest = requests[1].then((gasAccounts) => {
    if (accountId && (!gasAccounts || !isSubBalance(gasAccounts, accountId))) {
      throw new Error('No subbalance data found in GAS.');
    }

    if (accountId && isSubBalance(gasAccounts, accountId)) {
      return getSubbalanceClient({
        clientId,
        accountId,
        httpClient,
      });
    }
    return getClient({ clientId, httpClient });
  });

  const accountRequest = clientRequest.then(
    ({ data: { bank_account_identifier: bankAccountId } }) =>
      (bankAccountId &&
        getAccountById(httpClient, {
          accountId: bankAccountId,
        })) ||
      {}
  );

  return Promise.allSettled([...requests, clientRequest, accountRequest]).then(
    (result) => handleResult(result, accountId)
  );
};
