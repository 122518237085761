export const TRANSACTION_TYPE_PAY_IN = 'PAYIN';
export const TRANSACTION_TYPE_SELF_TRANSFER = 'SELF_TRANSFER';
export const TRANSACTION_TYPE_SELF_TRANSFER_RETURN = 'SELF_TRANSFER_RETURN';
export const TRANSACTION_TYPE_BALANCE_TO_BALANCE = 'BALANCE_TO_BALANCE';
export const TRANSACTION_TYPE_REFERRAL_BONUS = 'REFERRAL_BONUS';

export const BANK_TRANSFER = 'Credit transfer';
export const SELF_TRANSFER = 'Self transfer';

export const FAILED_STATUSES = ['FAILED', 'CANCELLED'];
