import { filter } from 'lodash';
import {
  MODULES,
  CLIENT_ONBOARD_STATUSES,
  PATHS,
  CONSUMER_ID_PREFIX,
} from 'variables';
import buildLink from './buildLink';
import { hasSectionAccess } from './permissions';

const restrictRoutes = ({
  appName = {},
  status,
  permissions,
  clientId = '',
  accountId,
}) =>
  filter(appName.sections || [], ({ link, permissions: { app, section } }) => {
    const isMainBalance =
      appName === MODULES.MAIN_BALANCE &&
      status === CLIENT_ONBOARD_STATUSES.ONBOARDED;
    const isActivation = isMainBalance && link === PATHS.activation;
    const isDirectDebits = isMainBalance && link === PATHS.direct_debits;
    const isConsumerId =
      clientId.substring(0, 1) === CONSUMER_ID_PREFIX &&
      clientId.indexOf('-') > 0;
    const directDebitsVisible = isDirectDebits && !isConsumerId;
    const isAccount = isMainBalance && link === PATHS.account;
    const isSubbalance = !!accountId;
    const isSubbalanceAccount =
      isSubbalance && buildLink([PATHS.account, accountId]);
    const isTransactions = isMainBalance && link === PATHS.transactions;
    const isSubbalanceTransactions =
      isSubbalance &&
      link === buildLink([PATHS.account, accountId, PATHS.transactions]);

    return (
      hasSectionAccess({ permissions, app, section }) &&
      (!isMainBalance || !isActivation) &&
      (!isDirectDebits || directDebitsVisible) &&
      (!isSubbalance ||
        isAccount ||
        isSubbalanceAccount ||
        isTransactions ||
        isSubbalanceTransactions)
    );
  });

export default restrictRoutes;
