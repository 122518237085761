import {
  DD_STATUS_PENDING,
  MANDATE_HISTORY_STATUSES,
} from 'components/DirectDebitMandates/constants';
import { ALL_FILTERS_VALUE } from 'components/OpsTransfers/constants';
import { join, trim } from 'lodash';
import { buildQueryParams, encodeToBase64 } from 'services/common';
import { downloadObjectUrl } from 'services/download';
import { URLS, PATHS, CARD_TYPES } from 'variables';
import { BILLING_AMOUNT } from '../components/CancelAuthorizations/fieldsConfig';

export const getUserDetails = ({ clientId, httpClient }) =>
  clientId && httpClient.get(`${URLS.users}?merchant_code=${clientId}`);

export const getTransaction = ({ txId, httpClient }) =>
  httpClient.get(`${URLS.transactionsV1}/${txId}`);

export const getClient = ({ clientId, httpClient }) =>
  clientId && httpClient.get(`${URLS.clients}/${clientId}`);

export const getSubbalanceClient = ({ clientId, accountId, httpClient }) =>
  httpClient.get(`${URLS.clients}/${clientId}/subbalance/${accountId}`);

export const getCardLimits = ({ cardId, httpClient }) =>
  httpClient.get(`${URLS.card}/${cardId}/limits`);

export const updateClient = ({ clientId, address, mobilePhone, httpClient }) =>
  httpClient.put(`${URLS.clients}/${clientId}`, {
    ...address,
    mobile_phone: mobilePhone,
  });

export const getUpdatedClientData = async ({ clientId, httpClient }) => {
  const {
    data: {
      personal_profile: { address, mobile_phone: mobilePhone },
    },
  } = await getUserDetails({ clientId, httpClient });

  const { data } = await updateClient({
    clientId,
    address,
    mobilePhone,
    httpClient,
  });

  return {
    data: {
      ...data,
      address: {
        ...address,
        address_line_1: address.address_line1,
        address_line_2: address.address_line2,
      },
    },
  };
};

export const getClientTransactions = ({
  clientId,
  httpClient,
  origin,
  page,
  pageSize,
  startDate,
  endDate,
  identifier,
  rrn,
  authCode,
  details,
  mcc,
  transactionType,
  transactionStatus,
  sortBy,
  sortDirection,
  direction,
  accountId,
}) =>
  httpClient.get(
    `${URLS.clientsV1}/${clientId}/${PATHS.transactions}${buildQueryParams([
      { param: 'origin', value: origin },
      { param: 'page', value: page },
      { param: 'page_size', value: pageSize },
      { param: 'start_date', value: startDate },
      { param: 'end_date', value: endDate },
      { param: 'identifier', value: identifier },
      { param: 'retrieval_reference_number', value: rrn },
      { param: 'authentication_code', value: authCode },
      { param: 'details', value: details },
      { param: 'merchant_category_code', value: mcc },
      { param: 'transaction_type', value: transactionType },
      { param: 'transaction_status', value: transactionStatus },
      { param: 'sort_by', value: sortBy },
      { param: 'sort_direction', value: sortDirection },
      { param: 'direction', value: direction },
      { param: 'account_id', value: accountId },
    ])}`
  );

export const cancelTransaction = ({ httpClient, clientId, txId }) =>
  httpClient.post(
    `${URLS.clients}/${clientId}/${PATHS.transactions}/${txId}/cancel`
  );

export const chargebackTransaction = ({ httpClient, clientId, txId }) =>
  httpClient.post(
    `${URLS.clients}/${clientId}/${PATHS.transactions}/${txId}/chargeback`
  );

export const getBankTransactionFraud = ({ httpClient, txId, reportId }) =>
  httpClient.get(`${URLS.transactionsV1}/${txId}/fraud_reports/${reportId}`, {
    headers: { 'Cache-Control': 'no-cache' },
  });

export const markBankTransactionFraud = ({
  httpClient,
  paymentId,
  type,
  subtype,
  date,
  status,
  recoveredAmount,
  reference,
  details,
}) =>
  httpClient.post(`${URLS.transactionsV1}/${paymentId}/fraud_reports`, {
    payment_id: paymentId,
    type,
    subtype,
    status,
    tx_reported_date: date,
    recovered_amount: recoveredAmount,
    reference,
    details,
  });

export const editBankTransactionFraud = ({
  httpClient,
  paymentId,
  type,
  subtype,
  date,
  status,
  recoveredAmount,
  reference,
  details,
  lastModified,
  reportId,
}) =>
  httpClient.put(
    `${URLS.transactionsV1}/${paymentId}/fraud_reports/${reportId}`,
    {
      payment_id: paymentId,
      type,
      subtype,
      status,
      tx_reported_date: date,
      recovered_amount: recoveredAmount,
      reference,
      details,
      id: reportId,
    },
    { headers: { 'If-Unmodified-Since': lastModified } }
  );

export const unmarkBankTransactionFraud = ({
  httpClient,
  paymentId,
  reportId,
  comment,
  lastModified,
}) =>
  httpClient.put(
    `${URLS.transactionsV1}/${paymentId}/fraud_reports/${reportId}/delete`,
    {
      id: reportId,
      comment,
    },
    { headers: { 'If-Unmodified-Since': lastModified } }
  );

export const renameClient = ({ clientId, name, httpClient }) =>
  httpClient.put(`${URLS.clients}/${clientId}/cardholder`, {
    cardholder_name: name,
  });

export const createBankAccount = ({
  clientId,
  address: { address_line_1: address, city, country },
  email,
  companyName,
  httpClient,
}) =>
  httpClient.post(`${URLS.clients}/${clientId}/account`, {
    address,
    city,
    country,
    email,
    company_name: companyName,
  });

export const getUserActivity = ({ clientId, httpClient }) =>
  httpClient.get(`${URLS.clients}/${clientId}/activity_log`);

export const getUserScaDevices = ({ clientId, httpClient }) =>
  httpClient.get(`${URLS.clients}/${clientId}/settings`);

export const getUserChallenges = ({ clientId, httpClient }) =>
  httpClient.get(`${URLS.clients}/${clientId}/${PATHS.challenges}`);

export const clearCardPinsAttempts = ({ cardId, httpClient }) =>
  httpClient.put(`${URLS.card}/${cardId}/pin`);

export const setCardStatus = ({
  clientId,
  cardId,
  status,
  comment,
  httpClient,
}) =>
  httpClient.put(`${URLS.clients}/${clientId}/cards/${cardId}/status`, {
    status,
    comment,
  });

export const lockCardForAudit = ({
  clientId,
  cardToken,
  action,
  comment,
  httpClient,
}) =>
  httpClient.put(
    `${URLS.adminClients}/${clientId}/cards/${cardToken}/${action}`,
    {
      comment,
    }
  );

export const saveCardLimits = ({ cardId, limits, httpClient }) =>
  httpClient.put(`${URLS.card}/${cardId}/limits`, {
    limits,
  });

export const enrollPsd = ({ cardId, httpClient }) =>
  httpClient.post(`${URLS.card}/${cardId}/enroll`);

export const unenrollPsd = ({ cardId, httpClient }) =>
  httpClient.delete(`${URLS.card}/${cardId}/unenroll`);

export const blockPsd = ({ cardId, httpClient }) =>
  httpClient.post(`${URLS.card}/${cardId}/block`);

export const unblockPsd = ({ cardId, httpClient }) =>
  httpClient.post(`${URLS.card}/${cardId}/unblock`);

export const onboardClient = ({
  clientId,
  firstName,
  lastName,
  mobilePhone,
  country,
  city,
  addressLine1,
  addressLine2,
  postCode,
  httpClient,
  cardType,
  pin,
}) =>
  httpClient.post(`${URLS.clients}/${clientId}`, {
    client_code: clientId,
    country,
    first_name: firstName,
    last_name: lastName,
    mobile_phone: mobilePhone,
    shipping_address: {
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city,
      country,
      post_code: postCode,
    },
    card_type: cardType,
    ...(cardType === CARD_TYPES.virtual && { pin }),
  });

export const issueCard = ({ clientId, language, httpClient, cardType, pin }) =>
  httpClient.post(URLS.card, {
    client_code: clientId,
    amount: 0,
    language,
    card_type: cardType,
    ...(cardType === CARD_TYPES.virtual && { pin }),
  });

export const getWalletTokens = ({ clientId, cardToken, httpClient }) =>
  httpClient.get(`${URLS.clients}/${clientId}/cards/${cardToken}/tokens`);

export const updateWalletToken = ({
  clientId,
  cardToken,
  tokenUniqueReference,
  tokenStatus,
  httpClient,
}) =>
  httpClient.patch(
    `${URLS.clients}/${clientId}/cards/${cardToken}/tokens/${tokenUniqueReference}/status`,
    {
      tokenStatus,
    }
  );

export const search = (query, httpClient) =>
  httpClient.get(`${URLS.search}?term=${query}`);

export const logOut = (httpClient) => httpClient.get(URLS.logout);

export const getUsersWithGroups = ({ httpClient }) =>
  httpClient.get(`${URLS.cardAdmin}/${PATHS.users}`);

export const getGroupsByEmail = ({ httpClient, email }) =>
  httpClient.get(`${URLS.users}/${encodeToBase64(email)}`);

export const createUser = ({ httpClient, email, groups }) =>
  httpClient.post(URLS.users, {
    email,
    groups,
  });

export const updateUser = ({ httpClient, email, groups }) =>
  httpClient.put(URLS.users, {
    email,
    groups,
  });

export const deleteUser = ({ httpClient, email }) =>
  httpClient.delete(`${URLS.users}/${encodeToBase64(email)}`);

export const getGroups = ({ httpClient }) => httpClient.get(URLS.groups);

export const getGroup = ({ httpClient, code }) =>
  httpClient.get(`${URLS.groups}/${encodeToBase64(code)}`);

export const createGroup = ({ httpClient, groupName, appCode, permissions }) =>
  httpClient.post(URLS.groups, {
    name: groupName,
    app_code: appCode,
    permissions,
  });

export const updateGroup = ({
  httpClient,
  groupName,
  newName,
  appCode,
  permissions,
}) =>
  httpClient.put(URLS.groups, {
    name: groupName,
    new_name: newName,
    app_code: appCode,
    permissions,
  });

export const deleteGroup = ({ httpClient, code }) =>
  httpClient.delete(`${URLS.groups}/${encodeToBase64(code)}`);

export const getApps = ({ httpClient }) => httpClient.get(URLS.apps);

export const getLoggedUserPermissions = ({ httpClient }) =>
  httpClient.get(URLS.permissions);

export const getFraudReport = ({ httpClient, fromDate, toDate }) =>
  httpClient.get(
    `${URLS.fraud}/${PATHS.transactions}/${PATHS.report}?fromDate=${fromDate}&toDate=${toDate}`
  );

export const getBankFraudReport = ({ httpClient, fromDate, toDate }) =>
  httpClient.get(
    `${URLS.transactionsV1}/fraud_reports/${PATHS.report}?start_date=${fromDate}&end_date=${toDate}`
  );

export const markAsPotentialFraud = ({
  httpClient,
  txId,
  fraudTypeCode,
  fraudSubtypeCode,
}) =>
  httpClient.post(`${URLS.fraud}/${PATHS.transactions}`, {
    transaction_identifier: txId,
    fraud_type_code: fraudTypeCode,
    fraud_subtype_code: fraudSubtypeCode,
  });

export const transferToBank = ({
  httpClient,
  clientId,
  type,
  amount,
  comment,
}) =>
  httpClient.post(`${URLS.clients}/${clientId}/self_transfer`, {
    type,
    amount,
    comment,
  });

export const getPrimaryBankAccount = ({ httpClient, merchantCode }) =>
  httpClient.get(`${URLS.merchants}/${merchantCode}/primary-bank-account`);

export const buildTransactionsExportUrl = ({
  clientId,
  origin,
  page,
  pageSize,
  startDate,
  endDate,
  identifier,
  rrn,
  authCode,
  details,
  mcc,
  transactionType,
  transactionStatus,
  sortBy,
  sortDirection,
  extension,
  accountId,
}) =>
  `${URLS.export}/${clientId}/${extension}${buildQueryParams([
    { param: 'origin', value: origin },
    { param: 'page', value: page },
    { param: 'page_size', value: pageSize },
    { param: 'start_date', value: startDate },
    { param: 'end_date', value: endDate },
    { param: 'identifier', value: identifier },
    { param: 'retrieval_reference_number', value: rrn },
    { param: 'authentication_code', value: authCode },
    { param: 'details', value: details },
    { param: 'merchant_category_code', value: mcc },
    { param: 'transaction_type', value: transactionType },
    { param: 'transaction_status', value: transactionStatus },
    { param: 'sort_by', value: sortBy },
    { param: 'sort_direction', value: sortDirection },
    { param: 'account_id', value: accountId },
  ])}`;

export const toggleDirectDebit = ({
  httpClient,
  accountId,
  enableDD,
  comment,
}) =>
  httpClient.patch(`${URLS.accounts}/${accountId}`, {
    direct_debits_enabled: enableDD,
    comment,
  });

export const getAccountById = (httpClient, { accountId }) =>
  httpClient.get(`${URLS.accounts}/${accountId}`);

export const blockBusinessAccount = (httpClient, { accountId, comment }) =>
  httpClient.put(`${URLS.accounts}/${accountId}/block`, {
    comment,
  });

export const unblockBusinessAccount = (httpClient, { accountId, comment }) =>
  httpClient.put(`${URLS.accounts}/${accountId}/unblock`, {
    comment,
  });

export const closeBusinessAccount = (httpClient, { accountId, comment }) =>
  httpClient.put(`${URLS.accounts}/${accountId}/close`, {
    comment,
  });

export const getAccountDetails = (httpClient, { merchantCode }) =>
  httpClient.get(`${URLS.accounts}?company_code=${merchantCode}`);

export const getGasAccounts = (httpClient, { merchantCode }) =>
  httpClient.get(`${URLS.gasAccounts}?client_code=${merchantCode}`);

export const getBankProfileByAccountId = (httpClient, { accountId }) =>
  httpClient.get(`${URLS.bankProfile}?account_id=${accountId}`);

export const createRecipient = (
  httpClient,
  { accountNumber, bic, fullName, iban, sortCode, userId }
) =>
  httpClient.post(`${URLS.contacts}`, {
    account_number: accountNumber,
    bic,
    full_name: fullName,
    iban,
    sort_code: sortCode,
    user_id: userId,
  });

export const getUnmatchedClearings = ({
  httpClient,
  from,
  to,
  file,
  token,
  rrn,
  page,
  size: pageSize,
  authCode,
}) =>
  httpClient.get(
    `${URLS.reconciliation}/${PATHS.clearings}/${
      PATHS.unmatched
    }${buildQueryParams([
      { param: 'from', value: from },
      { param: 'to', value: to },
      { param: 'file', value: file },
      { param: 'token', value: token },
      { param: 'rrn', value: rrn },
      { param: 'page', value: page },
      { param: 'size', value: pageSize },
      { param: 'authCode', value: authCode },
      { param: 'sortDirection', value: 'DESCENDING' },
    ])}`
  );

export const getCardAuthorizations = ({
  httpClient,
  cardToken,
  page,
  size: pageSize,
  from,
  to,
}) =>
  httpClient.get(
    `${URLS.reconciliation}/${PATHS.authorizations}/${cardToken}/${
      PATHS.unmatched
    }${buildQueryParams([
      { param: 'page', value: page },
      { param: 'size', value: pageSize },
      { param: 'from', value: from },
      { param: 'to', value: to },
    ])}`
  );

export const getAllAuthorizations = ({
  httpClient,
  page,
  size: pageSize,
  from,
  to,
  sortDirection,
  sortBy,
  authCode,
  cardToken,
  billingCurrency,
  transactionStatus,
}) => {
  let direction;
  let sortField;
  if (
    billingCurrency !== undefined &&
    sortDirection === undefined &&
    sortBy === undefined
  ) {
    direction = 'DESCENDING';
    sortField = BILLING_AMOUNT.sortName;
  } else {
    direction = sortDirection;
    sortField = sortBy;
  }

  return httpClient.get(
    `${URLS.reconciliation}/${PATHS.authorizations}/${
      PATHS.unmatched
    }${buildQueryParams([
      { param: 'page', value: page },
      { param: 'size', value: pageSize },
      { param: 'from', value: from },
      { param: 'to', value: to },
      { param: 'sortDirection', value: direction },
      { param: 'sortBy', value: sortField },
      { param: 'authCode', value: authCode },
      { param: 'cardToken', value: cardToken },
      { param: 'billingCurrency', value: billingCurrency },
      { param: 'transactionStatus', value: transactionStatus },
    ])}`
  );
};

export const matchAuthorization = ({
  httpClient,
  clearingIds,
  authorizationId,
  type,
}) =>
  httpClient.post(`${URLS.reconciliation}/${PATHS.clearings}/${PATHS.match}`, {
    clearingIds,
    authorization_id: authorizationId,
    type,
  });

export const buildDownloadTransferStatementUrl = ({ paymentId, locale }) =>
  `${URLS.payments}/${paymentId}/statement${locale ? `?locale=${locale}` : ''}`;

export const buildDownloadAccountStatementUrl = ({ accountId, locale }) =>
  `${URLS.accounts}/${accountId}/statement${locale ? `?locale=${locale}` : ''}`;

export const downloadAsBlob = (httpClient, { url, filename = '' }) =>
  httpClient.get(url, { responseType: 'arraybuffer' }).then((response) => {
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    // Workaround for IE11, inspired by https://github.com/jelmerdemaat/dwnld-attr-polyfill/
    const supportsDownloadAttribute = 'download' in document.createElement('a');
    const supportsMsSaveBlob =
      typeof window.navigator.msSaveBlob !== 'undefined';
    if (!supportsDownloadAttribute && supportsMsSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
      return null;
    }

    const objectURL = URL.createObjectURL(blob);
    downloadObjectUrl(objectURL, filename);

    return null;
  });

export const getMerchantAccountStatus = ({ httpClient, merchantCode }) =>
  httpClient.get(`${URLS.risk_profile}/${merchantCode}`);

export const getAuthorizationsForAdjustment = ({
  httpClient,
  page,
  size: pageSize,
  from,
  to,
  cardToken,
  transactionStatus,
}) =>
  httpClient.get(
    `${URLS.reconciliation}/${PATHS.authorizations}/${
      PATHS['for-adjustment']
    }${buildQueryParams([
      { param: 'page', value: page },
      { param: 'size', value: pageSize },
      { param: 'from', value: from },
      { param: 'to', value: to },
      { param: 'cardToken', value: cardToken },
      { param: 'transactionStatus', value: transactionStatus },
      { param: 'sortDirection', value: 'DESCENDING' },
    ])}`
  );

export const deleteScaDevice = ({ httpClient, deviceUuid, clientId }) =>
  httpClient.delete(`${URLS.clients}/${clientId}/settings/${deviceUuid}`);

export const adjustAuthorization = ({
  httpClient,
  authorizationAdjustmentId,
  action,
}) =>
  httpClient.post(
    `${URLS.reconciliation}/${PATHS.authorizations}/${authorizationAdjustmentId}/${action}`
  );

export const createUpdateOpsTransfer = (
  httpClient,
  {
    uuid,
    accountId,
    accountName,
    accountNumber,
    sortCode,
    iban,
    bic,
    amount,
    currency,
    reference,
    additionalInfo,
    comment,
    hideRecipientDetails,
  }
) =>
  httpClient.put(`${URLS.opsTransfers}/${uuid}`, {
    source_account_id: accountId,
    beneficiary: {
      account_name: accountName,
      account_number: accountNumber,
      sort_code: sortCode ? trim(sortCode).replace(/-/g, '') : undefined,
      iban,
      bic,
    },
    amount,
    currency,
    reference,
    additional_info: additionalInfo,
    show_beneficiary: !hideRecipientDetails,
    comment,
  });

export const getOpsTransfers = (
  httpClient,
  {
    currency,
    status,
    sourceAccountId,
    merchantCode,
    page,
    size,
    sort,
    direction,
  }
) =>
  httpClient.get(
    `${URLS.opsTransfers}${buildQueryParams([
      {
        param: 'currency',
        value:
          !currency || currency === ALL_FILTERS_VALUE ? undefined : currency,
      },
      {
        param: 'statuses',
        value: !status || status === ALL_FILTERS_VALUE ? undefined : [status],
      },
      { param: 'source_account_id', value: sourceAccountId },
      { param: 'merchant_code', value: merchantCode },
      { param: 'page', value: page - 1 },
      { param: 'size', value: size },
      {
        param: 'sort',
        value: sort ? `${sort},${direction}` : undefined,
      },
    ])}`
  );

export const cancelOpsTransfer = (httpClient, { id, comment }) =>
  httpClient.put(`${URLS.opsTransfers}/${id}/cancel`, { comment });

export const initiateOpsTransfer = (
  httpClient,
  {
    uuid,
    accountId,
    accountName,
    accountNumber,
    sortCode,
    iban,
    bic,
    amount,
    currency,
    reference,
    additionalInfo,
    comment,
    hideRecipientDetails,
  }
) =>
  httpClient.put(`${URLS.opsTransfers}/${uuid}/initiate`, {
    source_account_id: accountId,
    beneficiary: {
      account_name: accountName,
      account_number: accountNumber,
      sort_code: sortCode ? trim(sortCode).replace(/-/g, '') : undefined,
      iban,
      bic,
    },
    amount,
    currency,
    reference,
    additional_info: additionalInfo,
    show_beneficiary: !hideRecipientDetails,
    comment,
  });

export const preApproveOpsTransfer = (httpClient, { id, comment }) =>
  httpClient.put(`${URLS.opsTransfers}/${id}/pre_approve`, { comment });

export const approveOpsTransfer = (httpClient, { id, comment }) =>
  httpClient.put(`${URLS.opsTransfers}/${id}/approve`, { comment });

export const rejectOpsTransfer = (httpClient, { id, comment }) =>
  httpClient.put(`${URLS.opsTransfers}/${id}/reject`, { comment });

export const issueSelfTransferReturn = (
  httpClient,
  { token, amount, comment }
) =>
  httpClient.put(`${URLS.adminTransfers}/${token}/return`, { amount, comment });

export const getOpsTransferHistory = (httpClient, { id }) =>
  httpClient.get(`${URLS.opsTransfers}/${id}/history`);

export const getSchedulesByType = (
  httpClient,
  { accountId, page, size, statuses, types, sortBy, sortDirection }
) =>
  httpClient.get(
    `${URLS.paymentRequests}?account_id=${accountId}&page=${
      page - 1
    }&size=${size}&schedule_statuses=${statuses}&schedule_types=${types}&sort=${sortBy}${
      sortDirection ? `,${sortDirection}` : ''
    }`
  );

export const skipNextPayment = (httpClient, { id, accountId, comment }) =>
  httpClient.put(
    `${URLS.paymentRequests}/${id}/schedule/skip?accountId=${accountId}`,
    { comment }
  );

export const cancelSchedule = (httpClient, { id, comment }) =>
  httpClient.put(`${URLS.paymentRequests}/${id}/schedule/cancel`, { comment });

export const getScheduleTransactionHistory = (
  httpClient,
  { id, page, size, sortBy, sortDirection }
) =>
  httpClient.get(
    `${URLS.payments}?payment_request_id=${id}&page=${
      page - 1
    }&size=${size}&sort=${sortBy}${sortDirection ? `,${sortDirection}` : ''}`
  );

export const replaceCard = ({
  httpClient,
  cardId,
  clientId,
  cardType,
  pin,
  replaceReason,
  country,
  city,
  addressLine1,
  addressLine2,
  postCode,
}) => {
  const replaceCardUrl = `${URLS.clients}/${clientId}/cards/${cardId}/replace`;

  const requestBody = {
    replace_reason: replaceReason,
    card_type: cardType,
  };

  if (cardType === CARD_TYPES.virtual) {
    requestBody.pin = pin;
  } else {
    requestBody.shipping_address = {
      country,
      city,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      post_code: postCode,
    };
  }

  return httpClient.put(replaceCardUrl, requestBody);
};

export const getDirectDebitMandates = (
  httpClient,
  { accountId, active, page, size, sortBy, sortDirection }
) => {
  const sortFieldName = sortBy === 'beneficiary' ? 'beneficiary_name' : sortBy;

  return httpClient.get(
    `${
      URLS.directDebitMandates
    }?account_id=${accountId}&active=${active}&page=${
      page - 1
    }&size=${size}&sort=${sortFieldName}${
      sortDirection ? `,${sortDirection}` : ''
    }`
  );
};

export const cancelDirectDebitMandate = (httpClient, { id, comment }) =>
  httpClient.put(`${URLS.directDebitMandates}/${id}/cancel`, { comment });

export const getShippingInfo = (httpClient, { clientId, cardToken }) =>
  httpClient.get(`${URLS.clients}/${clientId}/cards/${cardToken}/shipment`);

export const getDirectDebits = (
  httpClient,
  { mandateId, page, size, sortBy, sortDirection }
) => {
  return httpClient.get(
    `${URLS.directDebits}?mandate_id=${mandateId}&status=${join(
      MANDATE_HISTORY_STATUSES,
      ','
    )}&page=${page - 1}&size=${size}&sort=${sortBy}${
      sortDirection ? `,${sortDirection}` : ''
    }`
  );
};

export const getUpcomingDirectDebits = (httpClient, { mandateId }) => {
  return httpClient.get(
    `${URLS.directDebits}?mandate_id=${mandateId}&status=${DD_STATUS_PENDING}`
  );
};

export const publishPaymentEvent = (httpClient, { paymentId }) =>
  httpClient.put(`${URLS.events}/payments/${paymentId}`);

export const getBankLimits = (httpClient, { accountId }) =>
  httpClient.get(`${URLS.accounts}/${accountId}?includeLimits=true`);
