import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PATHS, URLS } from 'variables';
import ClientDataContext from 'context/clientData';
import { NavigationComponent } from 'components/Navigation/NavigationStyled';
import BackButton from 'components/Navigation/components/BackButton';
import { buildModules } from 'services/modules';
import NavigationCards from './components/NavigationCards';
import ClientCards from './components/ClientCards';
import NavigationHeader from './components/NavigationHeader';
import TotalBalance from './components/TotalBalance/TotalBalance';
import {
  ClientPageNavigationContent,
  ClientCardsWrapper,
} from './MerchantNavigationStyled';

const MerchantNavigation = ({ render }) => {
  const history = useHistory();
  const { clientId } = useParams();
  const {
    state: { clientData, clientDataLoading, externalLinks = {} },
  } = useContext(ClientDataContext);
  const modules = buildModules(clientData);

  const { onboarding_status: onboarded, id: userId } = clientData;
  const { paysolut } = externalLinks;

  if (clientDataLoading) {
    return null;
  }

  return (
    (render === PATHS.client && (
      <>
        <NavigationComponent>
          <BackButton onClick={() => history.push('/')} />
          <ClientPageNavigationContent>
            <NavigationHeader render={render} modules={modules} />
            <ClientCardsWrapper>
              <ClientCards
                soapUrl={`${URLS.soap}/${userId}`}
                paysolutUrl={paysolut}
              />
            </ClientCardsWrapper>
          </ClientPageNavigationContent>
        </NavigationComponent>
        <TotalBalance />
        <NavigationCards
          clientId={clientId}
          onboarded={onboarded}
          modules={modules}
        />
      </>
    )) || (
      <NavigationComponent>
        <NavigationHeader
          render={render}
          backButtonUrl={`/${PATHS.client}/${clientId}`}
          modules={modules}
        />
      </NavigationComponent>
    )
  );
};

MerchantNavigation.propTypes = {
  render: PropTypes.string.isRequired,
};

export default MerchantNavigation;
