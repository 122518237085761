import keyMirror from 'key-mirror-nested';
import { sortBy } from 'lodash/fp';
import { getGasAccounts } from 'api';
import { STATUS_CODES } from 'variables';

const ACCOUNT_TYPES = keyMirror({
  MAIN: null,
  SUB: null,
});

const PRODUCT_TYPES = keyMirror({
  IBAN: null,
  CARD: null,
  VIRTUAL_CARD: null,
});

export const isSubBalance = (data, accountId) =>
  data.find((account) => account.id === accountId)?.type === ACCOUNT_TYPES.SUB;

export const getMainAccount = (data) =>
  data.find((account) => account.type === ACCOUNT_TYPES.MAIN);

export const getIbanProduct = (data) =>
  data
    .flatMap((account) => account.products || [])
    .find((product) => product.type === PRODUCT_TYPES.IBAN);

export const getIbanProductFromAccount = (data, accountId) =>
  data
    .find((account) => account.id === accountId)
    ?.products?.find((product) => product.type === PRODUCT_TYPES.IBAN);

export const getSubBalances = (data) =>
  sortBy(
    'created_at',
    data.filter((account) => account.type === ACCOUNT_TYPES.SUB)
  );

export const fetchGasAccounts = async (httpClient, clientId) =>
  getGasAccounts(httpClient, { merchantCode: clientId })
    .then(({ data: { data: accounts } }) => accounts)
    .catch((response) =>
      response.response.status === STATUS_CODES.NOT_FOUND ? [] : null
    );
