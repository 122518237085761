import {
  getUserDetails,
  getClient,
  setCardStatus as setCardStatusApiCall,
  createBankAccount as createBankAccountApiCall,
  issueCard as issueCardApiCall,
  clearCardPinsAttempts as clearCardPinsAttemptsApiCall,
  blockPsd as blocked,
  unblockPsd as unblocked,
  enrollPsd as enrolled,
  unenrollPsd as unenrolled,
  getPrimaryBankAccount,
  getCardLimits,
  getBankLimits,
} from 'api';
import {
  ACTIVE_CARD_STATUSES,
  CLIENT_ONBOARD_STATUSES,
  SUMUP_BANK_CODE,
  SUMUP_SWIFT_CODES,
  FR_SOLE_TRADER_SUFFIX,
  COUNTRIES,
  CARD_TYPES,
} from 'variables';
import { find } from 'lodash';
import {
  BANK_LIMITS_LOAD_ERROR,
  CARD_LIMITS_LOAD_ERROR,
} from 'components/Limits/constants';

const enrollment = {
  blocked,
  unblocked,
  enrolled,
  unenrolled,
};

export const createBankAccount = ({ clientId, address, httpClient }) =>
  getUserDetails({ clientId, httpClient }).then(
    ({
      data: {
        merchant_profile: {
          company_name: companyName,
          country,
          legal_type: { sole_trader: soleTrader },
        },
        personal_profile: { first_name: firstName, last_name: lastName },
        account: { username: email },
      },
    }) => {
      const soleTraderName = `${firstName} ${lastName} ${
        country === COUNTRIES.FR ? FR_SOLE_TRADER_SUFFIX : ''
      }`;

      return createBankAccountApiCall({
        clientId,
        companyName: soleTrader ? soleTraderName : companyName,
        email,
        address,
        httpClient,
      }).then(() => getClient({ clientId, httpClient }));
    }
  );

export const issueCard = ({
  clientId,
  amount,
  language,
  httpClient,
  cardType,
  pin,
}) =>
  issueCardApiCall({
    clientId,
    amount,
    language,
    httpClient,
    cardType,
    ...(cardType === CARD_TYPES.virtual && { pin }),
  }).then(() => getClient({ clientId, httpClient }));

export const setCardStatus = ({
  clientId,
  cardId,
  status,
  comment,
  httpClient,
}) =>
  setCardStatusApiCall({
    clientId,
    cardId,
    status,
    comment,
    httpClient,
  });

export const clearCardPinsAttempts = ({ cardId, httpClient }) =>
  clearCardPinsAttemptsApiCall({ cardId, httpClient });

export const setPsdStatus = ({ status, token, httpClient }) =>
  enrollment[status]({ cardId: token, httpClient });

const isSumupPrimaryBank = ({ bankCode, swift }) =>
  bankCode === SUMUP_BANK_CODE || !!SUMUP_SWIFT_CODES[swift];

export const transferToBankEnabled = async ({ httpClient, clientId }) => {
  let result = null;

  try {
    const { data } = await getPrimaryBankAccount({
      httpClient,
      merchantCode: clientId,
    });
    const { bank_code: bankCode, swift } = data || {};

    result = !!(bankCode || swift) && !isSumupPrimaryBank({ bankCode, swift });
  } catch {
    result = false;
  }

  return result;
};

export const getActiveCards = (clientData = {}) =>
  (clientData.cards || []).filter(({ status }) => ACTIVE_CARD_STATUSES[status]);

export const getPrimaryCard = (cards = []) =>
  find(cards, ({ is_primary: isPrimary }) => isPrimary) || {};

export const getReplacementCard = (cards = []) =>
  find(cards, ({ is_primary: isPrimary }) => !isPrimary) || {};

export const getClientDetailsWithLimits = async (
  httpClient,
  { clientId, cardId }
) => {
  let result = {};
  let canCreateBankAccount = false;
  let accountId = null;

  try {
    const { data: clientData } = await getClient({ clientId, httpClient });
    accountId = clientData?.bank_account_identifier;

    if (clientData.onboarding_status === CLIENT_ONBOARD_STATUSES.ONBOARDED) {
      result = {
        onboarded: true,
        data: clientData,
      };
      canCreateBankAccount = clientData.can_create_bank_account;
    } else {
      result = await getUserDetails({ clientId, httpClient });
    }
  } catch {
    result = await getUserDetails({ clientId, httpClient });
  }

  try {
    if (result.onboarded && cardId) {
      const {
        data: { limits: cardLimits },
      } = await getCardLimits({ cardId, httpClient });

      result = {
        ...result,
        cardLimits,
        loadCardLimitsError: null,
      };
    }
  } catch {
    result = {
      ...result,
      cardLimits: [],
      loadCardLimitsError: CARD_LIMITS_LOAD_ERROR,
    };
  }

  try {
    if (canCreateBankAccount && accountId) {
      const {
        data: {
          limits: bankLimits,
          direct_debits_enabled: directDebitsEnabled,
        },
      } = await getBankLimits(httpClient, { accountId });

      result = {
        ...result,
        bankLimits,
        directDebitsEnabled,
        loadBankLimitsError: null,
      };
    }
  } catch {
    result = {
      ...result,
      bankLimits: [],
      loadBankLimitsError: BANK_LIMITS_LOAD_ERROR,
    };
  }

  return result;
};
