import moment from 'moment';
import keyMirror from 'key-mirror-nested';
import {
  SORT_ORDER_ASCENDING,
  SORT_ORDER_DESCENDING,
  DATE_PERIOD_WEEK,
  DATE_PERIOD_MONTH,
  DATE_PERIOD_QUARTER,
  DATE_PERIOD_HALF_YEAR,
  DATE_PERIOD_YEAR,
  DATE_PERIOD_CUSTOM,
} from 'variables';
import { ReactComponent as Barcode } from './assets/barcode.svg';
import { ReactComponent as BarcodeApple } from './assets/barcode-apple.svg';
import { ReactComponent as BarcodeGoogle } from './assets/barcode-google.svg';
import { ReactComponent as BarcodeDefaultWallet } from './assets/barcode-default-wallet.svg';
import { ReactComponent as CardOnFile } from './assets/card-on-file.svg';
import { ReactComponent as CardOnFileApple } from './assets/card-on-file-apple.svg';
import { ReactComponent as CardOnFileGoogle } from './assets/card-on-file-google.svg';
import { ReactComponent as CardOnFileDefaultWallet } from './assets/card-on-file-default-wallet.svg';
import { ReactComponent as Chip } from './assets/chip.svg';
import { ReactComponent as ChipApple } from './assets/chip-apple.svg';
import { ReactComponent as ChipGoogle } from './assets/chip-google.svg';
import { ReactComponent as ChipDefaultWallet } from './assets/chip-default-wallet.svg';
import { ReactComponent as ContactlessMagstripe } from './assets/contactless-magstripe.svg';
import { ReactComponent as ContactlessMagstripeApple } from './assets/contactless-magstripe-apple.svg';
import { ReactComponent as ContactlessMagstripeGoogle } from './assets/contactless-magstripe-google.svg';
import { ReactComponent as ContactlessMagstripeDefaultWallet } from './assets/contactless-magstripe-default-wallet.svg';
import { ReactComponent as Contactless } from './assets/contactless.svg';
import { ReactComponent as ContactlessApple } from './assets/contactless-apple.svg';
import { ReactComponent as ContactlessGoogle } from './assets/contactless-google.svg';
import { ReactComponent as ContactlessDefaultWallet } from './assets/contactless-default-wallet.svg';
import { ReactComponent as eCommerce } from './assets/ecommerce.svg';
import { ReactComponent as eCommerceApple } from './assets/ecommerce-apple.svg';
import { ReactComponent as eCommerceGoogle } from './assets/ecommerce-google.svg';
import { ReactComponent as eCommerceDefaultWallet } from './assets/ecommerce-default-wallet.svg';
import { ReactComponent as MagstripeCvv } from './assets/magstripe-cvv.svg';
import { ReactComponent as MagstripeCvvApple } from './assets/magstripe-cvv-apple.svg';
import { ReactComponent as MagstripeCvvGoogle } from './assets/magstripe-cvv-google.svg';
import { ReactComponent as MagstripeCvvDefaultWallet } from './assets/magstripe-cvv-default-wallet.svg';
import { ReactComponent as MagstripeFallback } from './assets/magstripe-fallback.svg';
import { ReactComponent as MagstripeFallbackApple } from './assets/magstripe-fallback-apple.svg';
import { ReactComponent as MagstripeFallbackGoogle } from './assets/magstripe-fallback-google.svg';
import { ReactComponent as MagstripeFallbackDefaultWallet } from './assets/magstripe-fallback-default-wallet.svg';
import { ReactComponent as Magstripe } from './assets/magstripe.svg';
import { ReactComponent as MagstripeApple } from './assets/magstripe-apple.svg';
import { ReactComponent as MagstripeGoogle } from './assets/magstripe-google.svg';
import { ReactComponent as MagstripeDefaultWallet } from './assets/magstripe-default-wallet.svg';
import { ReactComponent as ManualEntry } from './assets/manual-entry.svg';
import { ReactComponent as ManualEntryApple } from './assets/manual-entry-apple.svg';
import { ReactComponent as ManualEntryGoogle } from './assets/manual-entry-google.svg';
import { ReactComponent as ManualEntryDefaultWallet } from './assets/manual-entry-default-wallet.svg';
import { ReactComponent as MobileMoto } from './assets/mobile-moto.svg';
import { ReactComponent as MobileMotoApple } from './assets/mobile-moto-apple.svg';
import { ReactComponent as MobileMotoGoogle } from './assets/mobile-moto-google.svg';
import { ReactComponent as MobileMotoDefaultWallet } from './assets/mobile-moto-default-wallet.svg';
import { ReactComponent as Ocr } from './assets/ocr.svg';
import { ReactComponent as OcrApple } from './assets/ocr-apple.svg';
import { ReactComponent as OcrGoogle } from './assets/ocr-google.svg';
import { ReactComponent as OcrDefaultWallet } from './assets/ocr-default-wallet.svg';
import { ReactComponent as PanAutoEntered } from './assets/pan-auto-entered.svg';
import { ReactComponent as PanAutoEnteredApple } from './assets/pan-auto-entered-apple.svg';
import { ReactComponent as PanAutoEnteredGoogle } from './assets/pan-auto-entered-google.svg';
import { ReactComponent as PanAutoEnteredDefaultWallet } from './assets/pan-auto-entered-default-wallet.svg';
import { ReactComponent as Unknown } from './assets/unknown.svg';
import { ReactComponent as UnknownApple } from './assets/unknown-apple.svg';
import { ReactComponent as UnknownGoogle } from './assets/unknown-google.svg';
import { ReactComponent as UnknownDefaultWallet } from './assets/unknown-default-wallet.svg';
import { ReactComponent as VoiceCallback } from './assets/voice-callback.svg';
import { ReactComponent as VoiceCallbackApple } from './assets/voice-callback-apple.svg';
import { ReactComponent as VoiceCallbackGoogle } from './assets/voice-callback-google.svg';
import { ReactComponent as VoiceCallbackDefaultWallet } from './assets/voice-callback-default-wallet.svg';

export const DATE_PERIOD_OPTIONS = {
  [DATE_PERIOD_WEEK]: {
    name: DATE_PERIOD_WEEK,
    label: 'Last 7 days',
    days: 7,
    startDate: moment().subtract(7, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_MONTH]: {
    name: DATE_PERIOD_MONTH,
    label: 'Last 30 days',
    days: 30,
    startDate: moment().subtract(30, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_QUARTER]: {
    name: DATE_PERIOD_QUARTER,
    label: 'Last 90 days',
    days: 90,
    startDate: moment().subtract(90, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_HALF_YEAR]: {
    name: DATE_PERIOD_HALF_YEAR,
    label: 'Last 180 days',
    days: 180,
    startDate: moment().subtract(180, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_YEAR]: {
    name: DATE_PERIOD_YEAR,
    label: 'Last 365 days',
    days: 365,
    startDate: moment().subtract(365, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_CUSTOM]: {
    name: DATE_PERIOD_CUSTOM,
    label: 'Custom period',
    days: null,
  },
};

export const ALL_TRANSACTION_ORIGINS = 'ALL';
export const BANK_TRANSACTION_ORIGIN = 'BANK';
export const CARD_TRANSACTION_ORIGIN = 'CARD';
export const DIRECT_DEBIT_TRANSACTION_ORIGIN = 'DIRECT_DEBIT';
export const ALL_TRANSACTION_DIRECTIONS = 'ALL';
export const TRANSACTION_DIRECTION_DEBIT = 'DEBIT';
export const TRANSACTION_DIRECTION_CREDIT = 'CREDIT';

export const TRANSACTION_ORIGIN_FILTER_OPTIONS = {
  [ALL_TRANSACTION_ORIGINS]: {
    value: ALL_TRANSACTION_ORIGINS,
    label: 'All',
    color: '#3063E9',
  },
  [BANK_TRANSACTION_ORIGIN]: {
    value: BANK_TRANSACTION_ORIGIN,
    label: 'Bank',
    color: '#9AAB32',
  },
  [CARD_TRANSACTION_ORIGIN]: {
    value: CARD_TRANSACTION_ORIGIN,
    label: 'Card',
    color: '#FBA75F',
  },
  [DIRECT_DEBIT_TRANSACTION_ORIGIN]: {
    value: DIRECT_DEBIT_TRANSACTION_ORIGIN,
    label: 'Direct debit',
    color: '#52B6FF',
  },
};

export const TRANSACTION_DIRECTION_FILTER_OPTIONS = {
  [ALL_TRANSACTION_DIRECTIONS]: {
    value: ALL_TRANSACTION_DIRECTIONS,
    label: 'All',
  },
  [TRANSACTION_DIRECTION_CREDIT]: {
    value: TRANSACTION_DIRECTION_CREDIT,
    label: 'In',
  },
  [TRANSACTION_DIRECTION_DEBIT]: {
    value: TRANSACTION_DIRECTION_DEBIT,
    label: 'Out',
  },
};

export const POS_ENTRY_MODE_MAP = {
  BARCODE: {
    label: 'Barcode',
    component: Barcode,
    appleWalletComponent: BarcodeApple,
    googleWalletComponent: BarcodeGoogle,
    defaultWalletComponent: BarcodeDefaultWallet,
  },
  CARD_ON_FILE: {
    label: 'Card on file',
    component: CardOnFile,
    appleWalletComponent: CardOnFileApple,
    googleWalletComponent: CardOnFileGoogle,
    defaultWalletComponent: CardOnFileDefaultWallet,
  },
  CHIP: {
    label: 'Chip',
    component: Chip,
    appleWalletComponent: ChipApple,
    googleWalletComponent: ChipGoogle,
    defaultWalletComponent: ChipDefaultWallet,
  },
  CHIP_CVV_OPTIONAL: {
    label: 'Chip (CVV optional)',
    component: Chip,
    appleWalletComponent: ChipApple,
    googleWalletComponent: ChipGoogle,
    defaultWalletComponent: ChipDefaultWallet,
  },
  CONTACTLESS: {
    label: 'Contactless',
    component: Contactless,
    appleWalletComponent: ContactlessApple,
    googleWalletComponent: ContactlessGoogle,
    defaultWalletComponent: ContactlessDefaultWallet,
  },
  CONTACTLESS_MAGSTRIPE: {
    label: 'Contactless Magstripe',
    component: ContactlessMagstripe,
    appleWalletComponent: ContactlessMagstripeApple,
    googleWalletComponent: ContactlessMagstripeGoogle,
    defaultWalletComponent: ContactlessMagstripeDefaultWallet,
  },
  ECOMMERCE: {
    label: 'E-Commerce',
    component: eCommerce,
    appleWalletComponent: eCommerceApple,
    googleWalletComponent: eCommerceGoogle,
    defaultWalletComponent: eCommerceDefaultWallet,
  },
  MAGSTRIPE: {
    label: 'Magstripe',
    component: Magstripe,
    appleWalletComponent: MagstripeApple,
    googleWalletComponent: MagstripeGoogle,
    defaultWalletComponent: MagstripeDefaultWallet,
  },
  MAGSTRIPE_CVV: {
    label: 'Magstripe CVV',
    component: MagstripeCvv,
    appleWalletComponent: MagstripeCvvApple,
    googleWalletComponent: MagstripeCvvGoogle,
    defaultWalletComponent: MagstripeCvvDefaultWallet,
  },
  MAGSTRIPE_FALLBACK: {
    label: 'Magstripe fallback',
    component: MagstripeFallback,
    appleWalletComponent: MagstripeFallbackApple,
    googleWalletComponent: MagstripeFallbackGoogle,
    defaultWalletComponent: MagstripeFallbackDefaultWallet,
  },
  MANUAL_ENTRY: {
    label: 'Manual entry',
    component: ManualEntry,
    appleWalletComponent: ManualEntryApple,
    googleWalletComponent: ManualEntryGoogle,
    defaultWalletComponent: ManualEntryDefaultWallet,
  },
  MOBILE_MOTO: {
    label: 'Mobile/MOTO',
    component: MobileMoto,
    appleWalletComponent: MobileMotoApple,
    googleWalletComponent: MobileMotoGoogle,
    defaultWalletComponent: MobileMotoDefaultWallet,
  },
  OCR: {
    label: 'OCR',
    component: Ocr,
    appleWalletComponent: OcrApple,
    googleWalletComponent: OcrGoogle,
    defaultWalletComponent: OcrDefaultWallet,
  },
  PAN_AUTO_ENTERED: {
    label: 'PAN auto entered',
    component: PanAutoEntered,
    appleWalletComponent: PanAutoEnteredApple,
    googleWalletComponent: PanAutoEnteredGoogle,
    defaultWalletComponent: PanAutoEnteredDefaultWallet,
  },
  UNKNOWN: {
    label: 'Unknown',
    component: Unknown,
    appleWalletComponent: UnknownApple,
    googleWalletComponent: UnknownGoogle,
    defaultWalletComponent: UnknownDefaultWallet,
  },
  VOICE_CALLBACK: {
    label: 'Voice callback',
    component: VoiceCallback,
    appleWalletComponent: VoiceCallbackApple,
    googleWalletComponent: VoiceCallbackGoogle,
    defaultWalletComponent: VoiceCallbackDefaultWallet,
  },
};

export const TRANSACTION_STATUSES = keyMirror({
  APPROVED: null,
  PAYIN: null,
  PROCESSING: null,
  DECLINED: null,
  REFUNDED: null,
  FAILED: null,
  BANK_CANCELLED: null,
  RETURN: null,
});

export const TRANSACTION_STATUS_MAP = {
  [TRANSACTION_STATUSES.APPROVED]: {
    name: TRANSACTION_STATUSES.APPROVED,
    background: '#3AC20A',
    label: 'Approved',
  },
  [TRANSACTION_STATUSES.PAYIN]: {
    name: TRANSACTION_STATUSES.PAYIN,
    background: '#0D69F2',
    label: 'Pay-in',
  },
  [TRANSACTION_STATUSES.PROCESSING]: {
    name: TRANSACTION_STATUSES.PROCESSING,
    background: '#12C9C9',
    label: 'Processing',
  },
  [TRANSACTION_STATUSES.DECLINED]: {
    name: TRANSACTION_STATUSES.DECLINED,
    background: '#DD1616',
    label: 'Declined',
  },
  [TRANSACTION_STATUSES.REFUNDED]: {
    name: TRANSACTION_STATUSES.REFUNDED,
    background: '#8B18B4',
    label: 'Refunded',
  },
  [TRANSACTION_STATUSES.FAILED]: {
    name: TRANSACTION_STATUSES.FAILED,
    background: '#63231C',
    label: 'Failed',
  },
  [TRANSACTION_STATUSES.RETURN]: {
    name: TRANSACTION_STATUSES.RETURN,
    background: '#8B18B4',
    label: 'Return',
  },
};

export const BANK_TRANSACTION_STATUS_MAP = {
  [TRANSACTION_STATUSES.BANK_CANCELLED]: {
    name: TRANSACTION_STATUSES.BANK_CANCELLED,
    background: '#DD1616',
    label: 'Cancelled',
  },
};

export const TRANSACTION_FILTERS_ANY_OPTION = { name: 'any', label: 'Any' };

export const TRANSACTION_STATUS_FILTER = 'transactionStatus';
export const TRANSACTION_TYPE_FILTER = 'transactionType';
export const TRANSACTION_MCC_FILTER = 'mcc';
export const TRANSACTION_ID_FILTER = 'identifier';
export const TRANSACTION_RRN_FILTER = 'rrn';
export const TRANSACTION_AUTH_CODE_FILTER = 'authCode';
export const TRANSACTION_DETAILS_FILTER = 'details';

// String fields that might be a valid number, but should not be parsed as such
export const TRANSACTION_NON_NUMERIC_FIELDS_MAP = {
  transaction_server_date: true,
  clearing_date: true,
  conversion_rate_billing: true,
  merchant_category: true,
};

export const TRANSACTIONS_GRID_FIRST_PAGE = 1;

export const DIRECTION_DEBIT = 'DEBIT';
export const DIRECTION_CREDIT = 'CREDIT';

export const TRANSACTIONS_CLEARING_SECTION_KEY = 'TransactionClearingSection';

export const FRAUD_TYPE_MAP = {
  '00': '00 - Lost',
  '01': '01 - Stolen',
  '02': '02 - Never Received',
  '03': '03 - Fraud App',
  '04': '04 - Counterfeit',
  '05': '05 - Account Takeover',
  '06': '06 - Card Not Present',
  51: '51 - Bustout Collusv Mrch',
  55: '55 - Modification of Payment',
  56: '56 - Manipulation of the cardholder',
  57: '57 - First-Party Fraud',
};

export const FRAUD_SUB_TYPE_MAP = {
  K: 'K - Convenience check',
  N: 'N - PIN not used',
  P: 'P - PIN used',
  U: 'U - unknown',
};

export const FRAUD_SUB_TYPE_MAP_FULL = {
  A: 'A - Advance Fee scam',
  H: 'H - Purchase Scam',
  I: 'I - Impersonation Scam',
  K: 'K - Convenience check',
  N: 'N - PIN not used',
  P: 'P - PIN used',
  R: 'R - Romance Scam',
  U: 'U - unknown',
  V: 'V  - Investment Scam',
};

export const BANK_TRX_FRAUD_TYPE_MAP = {
  SCAM_ALERT: 'Scam alert',
  CANCELLATION_REQUEST: 'Cancellation request',
};

export const BANK_TRX_FRAUD_SCAM_ALERT_SUB_TYPE_MAP = {
  ADVANCE: 'Advance fee/Purchase scam',
  IMPERSONATION1: 'Impersonation Bank/Agency/Police',
  IMPERSONATION2: 'Impersonation Company/Family/CEO',
  PHISHING: 'Phishing, vishing and smishing',
  INVESTIGATION: 'Investment scam',
  HOLIDAY: 'Tour operator and holiday scam',
  INVOICE: 'Invoice and mandate scam',
  TICKET: 'Ticket scam',
  LOAN: 'Loan/Pension scam',
  GEN2: '2nd gen fraudulent funds',
  GEN3: '3rd gen fraudulent funds',
  ACC_TAKEOVER: 'Account takeover',
  ROMANCE: 'Romance fraud',
  OTHER: 'Other',
  NO_INFO: 'No Info Available',
};

export const BANK_TRX_FRAUD_CANCELLATION_REQUEST_SUB_TYPE_MAP = {
  FRAD: 'FRAD',
  CUST: 'CUST',
  AM09: 'AM09',
  AC03: 'AC03',
  DUPL: 'DUPL',
  TECH: 'TECH',
};

export const MARK_AS_FRAUD_ACTION = 'MARK_AS_FRAUD_ACTION';
export const MARK_FRAUD_REPORT_ACTION = 'MARK_FRAUD_REPORT_ACTION';
export const EDIT_FRAUD_REPORT_ACTION = 'EDIT_FRAUD_REPORT_ACTION';
export const UNMARK_FRAUD_REPORT_ACTION = 'UNMARK_FRAUD_REPORT_ACTION';
export const DOWNLOAD_STATEMENT_ACTION = 'DOWNLOAD_STATEMENT_ACTION';
export const FRAUD_REPORT_DATE_FORMAT = 'YYYY-MM-DD';
export const REQUEST_OPS_TRANSFER_ACTION = 'REQUEST_OPS_TRANSFER_ACTION';
export const ISSUE_A_RETURN_ACTION = 'ISSUE_A_RETURN_ACTION';

export const UNMARK_FRAUD_DIALOG_WIDTH = 420;
export const UNMARK_FRAUD_DIALOG_HEIGHT = 240;

export const MESSAGE_TYPE_MAP = {
  ADVICE: 'Advice',
  AUTH: 'Authorization',
  FIN: 'Financial message',
  REVERSAL_ADVICE: 'Reversal advice',
  UNKNOWN: 'Unknown',
};

export const TRANSACTION_TYPES = keyMirror({
  ACCOUNT_FUNDING: null,
  ACCOUNT_TRANSFER: null,
  ACCOUNT_VERIFICATION: null,
  ATM_BALANCE_INQUIRY: null,
  ATM_WITHDRAW: null,
  BALANCE_TO_BALANCE: null,
  CARD_CONTROL_REQUEST: null,
  CARD_ON_FILE: null,
  CASH_ADVANCE: null,
  CASH_DEPOSIT: null,
  CASH_WITHDRAWAL: null,
  CHARGEBACK: null,
  CHARGEBACK_REVERSAL: null,
  CHECK_DEPOSIT_GUARANTEE: null,
  CHECK_DEPOSIT: null,
  CREDIT_ADJUSTMENT: null,
  CREDIT_TRANSFER: null,
  DEBIT_ADJUSTMENT: null,
  DEPOSIT: null,
  MINI_STATEMENT: null,
  MONEY_SEND: null,
  PAYIN: null,
  PIN_CHANGE_REQUEST: null,
  PURCHASE: null,
  PURCHASE_RETURN: null,
  PURCHASE_WITH_CASHBACK: null,
  REFERRAL_BONUS: null,
  RFU: null,
  SELF_TRANSFER: null,
  SCRIP_ISSUE: null,
  UNIQUE_QUASI_CASH: null,
  DIRECT_DEBIT: null,
  INBOUND: null,
  OUTBOUND: null,
});

export const TRANSACTION_TYPE_MAP = {
  [TRANSACTION_TYPES.ACCOUNT_FUNDING]: {
    name: TRANSACTION_TYPES.ACCOUNT_FUNDING,
    label: 'Account funding',
  },
  [TRANSACTION_TYPES.ACCOUNT_TRANSFER]: {
    name: TRANSACTION_TYPES.ACCOUNT_TRANSFER,
    label: 'Account transfer',
  },
  [TRANSACTION_TYPES.ACCOUNT_VERIFICATION]: {
    name: TRANSACTION_TYPES.ACCOUNT_VERIFICATION,
    label: 'Account verification',
  },
  [TRANSACTION_TYPES.ATM_BALANCE_INQUIRY]: {
    name: TRANSACTION_TYPES.ATM_BALANCE_INQUIRY,
    label: 'ATM balance inquiry',
  },
  [TRANSACTION_TYPES.ATM_WITHDRAW]: {
    name: TRANSACTION_TYPES.ATM_WITHDRAW,
    label: 'ATM withdraw',
  },
  [TRANSACTION_TYPES.BALANCE_TO_BALANCE]: {
    name: TRANSACTION_TYPES.BALANCE_TO_BALANCE,
    label: 'Money movement',
  },
  [TRANSACTION_TYPES.CARD_CONTROL_REQUEST]: {
    name: TRANSACTION_TYPES.CARD_CONTROL_REQUEST,
    label: 'Card control request',
  },
  [TRANSACTION_TYPES.CARD_ON_FILE]: {
    name: TRANSACTION_TYPES.CARD_ON_FILE,
    label: 'Card on file token processing',
  },
  [TRANSACTION_TYPES.CASH_ADVANCE]: {
    name: TRANSACTION_TYPES.CASH_ADVANCE,
    label: 'Cash advance',
  },
  [TRANSACTION_TYPES.CASH_DEPOSIT]: {
    name: TRANSACTION_TYPES.CASH_DEPOSIT,
    label: 'Cash deposit',
  },
  [TRANSACTION_TYPES.CASH_WITHDRAWAL]: {
    name: TRANSACTION_TYPES.CASH_WITHDRAWAL,
    label: 'Cash withdrawal',
  },
  [TRANSACTION_TYPES.CHARGEBACK]: {
    name: TRANSACTION_TYPES.CHARGEBACK,
    label: 'Chargeback',
  },
  [TRANSACTION_TYPES.CHARGEBACK_REVERSAL]: {
    name: TRANSACTION_TYPES.CHARGEBACK_REVERSAL,
    label: 'Chargeback reversal',
  },
  [TRANSACTION_TYPES.CHECK_DEPOSIT_GUARANTEE]: {
    name: TRANSACTION_TYPES.CHECK_DEPOSIT_GUARANTEE,
    label: 'Check deposit guarantee',
  },
  [TRANSACTION_TYPES.CHECK_DEPOSIT]: {
    name: TRANSACTION_TYPES.CHECK_DEPOSIT,
    label: 'Check deposit',
  },
  [TRANSACTION_TYPES.CREDIT_ADJUSTMENT]: {
    name: TRANSACTION_TYPES.CREDIT_ADJUSTMENT,
    label: 'Credit adjustment',
  },
  [TRANSACTION_TYPES.CREDIT_TRANSFER]: {
    name: TRANSACTION_TYPES.CREDIT_TRANSFER,
    label: 'Credit transfer',
  },
  [TRANSACTION_TYPES.DEBIT_ADJUSTMENT]: {
    name: TRANSACTION_TYPES.DEBIT_ADJUSTMENT,
    label: 'Debit adjustment',
  },
  [TRANSACTION_TYPES.DEPOSIT]: {
    name: TRANSACTION_TYPES.DEPOSIT,
    label: 'Deposit',
  },
  [TRANSACTION_TYPES.MINI_STATEMENT]: {
    name: TRANSACTION_TYPES.MINI_STATEMENT,
    label: 'Mini statement',
  },
  [TRANSACTION_TYPES.MONEY_SEND]: {
    name: TRANSACTION_TYPES.MONEY_SEND,
    label: 'Money send',
  },
  [TRANSACTION_TYPES.PAYIN]: { name: TRANSACTION_TYPES.PAYIN, label: 'Pay-in' },
  [TRANSACTION_TYPES.PIN_CHANGE_REQUEST]: {
    name: TRANSACTION_TYPES.PIN_CHANGE_REQUEST,
    label: 'PIN change request',
  },
  [TRANSACTION_TYPES.PURCHASE]: {
    name: TRANSACTION_TYPES.PURCHASE,
    label: 'Purchase',
  },
  [TRANSACTION_TYPES.PURCHASE_RETURN]: {
    name: TRANSACTION_TYPES.PURCHASE_RETURN,
    label: 'Purchase return',
  },
  [TRANSACTION_TYPES.PURCHASE_WITH_CASHBACK]: {
    name: TRANSACTION_TYPES.PURCHASE_WITH_CASHBACK,
    label: 'Purchase with cashback',
  },
  [TRANSACTION_TYPES.REFERRAL_BONUS]: {
    name: TRANSACTION_TYPES.REFERRAL_BONUS,
    label: 'Referral bonus',
  },
  [TRANSACTION_TYPES.RFU]: { name: TRANSACTION_TYPES.RFU, label: 'RFU' },
  [TRANSACTION_TYPES.SELF_TRANSFER]: {
    name: TRANSACTION_TYPES.SELF_TRANSFER,
    label: 'Self transfer',
  },
  [TRANSACTION_TYPES.SCRIP_ISSUE]: {
    name: TRANSACTION_TYPES.SCRIP_ISSUE,
    label: 'Scrip issue',
  },
  [TRANSACTION_TYPES.UNIQUE_QUASI_CASH]: {
    name: TRANSACTION_TYPES.UNIQUE_QUASI_CASH,
    label: 'Unique (Quasi-Cash)',
  },
};

export const BANK_TRANSACTION_TYPE_MAP = {
  [TRANSACTION_TYPES.DIRECT_DEBIT]: {
    name: TRANSACTION_TYPES.DIRECT_DEBIT,
    label: 'Direct debit',
  },
};

export const SORT_DIRECTION_MAP = {
  [SORT_ORDER_ASCENDING]: 'ASCENDING',
  [SORT_ORDER_DESCENDING]: 'DESCENDING',
};

export const APPROVED_RESPONSE_CODE = '00';

export const RESPONSES_MAP = {
  ALLOWED_NUMBER_OF_PIN_TRIES_EXCEEDED: {
    name: 'ALLOWED_NUMBER_OF_PIN_TRIES_EXCEEDED',
    label: 'Allowed number of PIN tries exceeded',
    code: '75',
  },
  APPROVED: {
    name: 'APPROVED',
    label: 'Approved',
    code: APPROVED_RESPONSE_CODE,
  },
  AUTHORIZATION_SYSTEM_OR_ISSUER_SYSTEM_INOPERABLE: {
    name: 'AUTHORIZATION_SYSTEM_OR_ISSUER_SYSTEM_INOPERABLE',
    label: 'Authorization system or issuer system inoperable',
    code: '91',
  },
  CONTACT_CARD_ISSUER: {
    name: 'CONTACT_CARD_ISSUER',
    label: 'Contact card issuer',
    code: '70',
  },
  CRYPTOGRAPHIC_FAILURE: {
    name: 'CRYPTOGRAPHIC_FAILURE',
    label: 'Cryptographic failure',
    code: '88',
  },
  CUSTOMER_CANCELLATION: {
    name: 'CUSTOMER_CANCELLATION',
    label: 'Customer cancellation',
    code: '17',
  },
  DO_NOT_HONOUR: {
    name: 'DO_NOT_HONOUR',
    label: 'Do not honour',
    code: '05',
  },
  DUPLICATE_TRANSACTION: {
    name: 'DUPLICATE_TRANSACTION',
    label: 'Duplicate transaction',
    code: '94',
  },
  ERROR: {
    name: 'ERROR',
    label: 'Error',
    code: '06',
  },
  EXCEEDS_WITHDRAWAL_AMOUNT_LIMIT: {
    name: 'EXCEEDS_WITHDRAWAL_AMOUNT_LIMIT',
    label: 'Exceeds withdrawal amount limit',
    code: '61',
  },
  EXCEEDS_WITHDRAWAL_COUNT_LIMIT: {
    name: 'EXCEEDS_WITHDRAWAL_COUNT_LIMIT',
    label: 'Exceeds withdrawal count limit',
    code: '65',
  },
  EXPIRED_CARD: {
    name: 'EXPIRED_CARD',
    label: 'Expired card',
    code: '54',
  },
  FORMAT_ERROR: {
    name: 'FORMAT_ERROR',
    label: 'Format error',
    code: '30',
  },
  HONOUR_WITH_ID: {
    name: 'HONOUR_WITH_ID',
    label: 'Honor with ID',
    code: '08',
  },
  INCORRECT_PIN: {
    name: 'INCORRECT_PIN',
    label: 'PIN incorrect',
    code: '55',
  },
  INSUFFICIENT_FUNDS: {
    name: 'INSUFFICIENT_FUNDS',
    label: 'Insufficient funds/over credit limit',
    code: '51',
  },
  INVALID_AMOUNT: {
    name: 'INVALID_AMOUNT',
    label: 'Invalid amount',
    code: '13',
  },
  INVALID_AUTHORIZATION_LIFE_CYCLE: {
    name: 'INVALID_AUTHORIZATION_LIFE_CYCLE',
    label: 'Invalid authorization life cycle',
    code: '84',
  },
  INVALID_CARD_NUMBER: {
    name: 'INVALID_CARD_NUMBER',
    label: 'Invalid card number',
    code: '14',
  },
  INVALID_MERCHANT: {
    name: 'INVALID_MERCHANT',
    label: 'Invalid merchant',
    code: '03',
  },
  INVALID_ISSUER: {
    name: 'INVALID_ISSUER',
    label: 'Invalid issuer',
    code: '15',
  },
  INVALID_NONEXISTENT_ACCOUNT_SPECIFIED_GENERAL: {
    name: 'INVALID_NONEXISTENT_ACCOUNT_SPECIFIED_GENERAL',
    label: 'INVALID/NONEXISTENT ACCOUNT SPECIFIED (general)',
    code: '78',
  },
  INVALID_NONEXISTENT_FROM_ACCOUNT_SPECIFIED: {
    name: 'INVALID_NONEXISTENT_FROM_ACCOUNT_SPECIFIED',
    label: "Invalid/nonexistent 'From account' specified",
    code: '77',
  },
  INVALID_NONEXISTENT_TO_ACCOUNT_SPECIFIED: {
    name: 'INVALID_NONEXISTENT_TO_ACCOUNT_SPECIFIED',
    label: "Invalid/nonexistent 'To account' specified",
    code: '76',
  },
  INVALID_TRANSACTION: {
    name: 'INVALID_TRANSACTION',
    label: 'Invalid transaction',
    code: '12',
  },
  LOST_CARD_PICK_UP: {
    name: 'LOST_CARD_PICK_UP',
    label: 'Lost card pick up',
    code: '41',
  },
  NO_ACTION_TAKEN: {
    name: 'NO_ACTION_TAKEN',
    label: 'No action taken',
    code: '21',
  },
  NO_CHECKING_ACCOUNT: {
    name: 'NO_CHECKING_ACCOUNT',
    label: 'No checking account',
    code: '52',
  },
  NO_SAVINGS_ACCOUNT: {
    name: 'NO_SAVINGS_ACCOUNT',
    label: 'No savings account',
    code: '53',
  },
  NOT_DECLINED_ZERO_AMOUNT_TRANSACTION: {
    name: 'NOT_DECLINED_ZERO_AMOUNT_TRANSACTION',
    label: 'Not declined. Valid for zero amount transactions.',
    code: '85',
  },
  PARTIAL_APPROVAL: {
    name: 'PARTIAL_APPROVAL',
    label: 'Partial approval',
    code: '10',
  },
  PARTIAL_REVERSAL: {
    name: 'PARTIAL_REVERSAL',
    label: 'Partial reversal',
    code: '32',
  },
  PICK_UP_CARD: {
    name: 'PICK_UP_CARD',
    label: 'Pick up card',
    code: '04',
  },
  PICK_UP_CARD_SPECIAL_CONDITION: {
    name: 'PICK_UP_CARD_SPECIAL_CONDITION',
    label: 'Pick up card',
    code: '07',
  },
  PIN_NOT_CHANGED: {
    name: 'PIN_NOT_CHANGED',
    label: 'PIN not changed',
    code: '71',
  },
  PIN_VALIDATION_NOT_POSSIBLE: {
    name: 'PIN_VALIDATION_NOT_POSSIBLE',
    label: 'PIN validation not possible',
    code: '86',
  },
  PURCHASE_AMOUNT_ONLY_NO_CASHBACK_ALLOWED: {
    name: 'PURCHASE_AMOUNT_ONLY_NO_CASHBACK_ALLOWED',
    label: 'Purchase amount only',
    code: '87',
  },
  REENTER_TRANSACTION: {
    name: 'REENTER_TRANSACTION',
    label: 'Re-enter transaction',
    code: '19',
  },
  REFER_TO_CARD_ISSUER: {
    name: 'REFER_TO_CARD_ISSUER',
    label: 'Refer to card issuer',
    code: '01',
  },
  REFER_TO_CARD_ISSUER_SPECIAL_CONDITION: {
    name: 'REFER_TO_CARD_ISSUER_SPECIAL_CONDITION',
    label: 'Refer to card issuer',
    code: '02',
  },
  RESPONSE_RECEIVED_LATE: {
    name: 'RESPONSE_RECEIVED_LATE',
    label: 'Response received late',
    code: '68',
  },
  RESTRICTED_CARD: {
    name: 'RESTRICTED_CARD',
    label: 'Restricted card',
    code: '62',
  },
  SECURITY_VIOLATION: {
    name: 'SECURITY_VIOLATION',
    label: 'Security violation',
    code: '63',
  },
  STOLEN_CARD_PICK_UP: {
    name: 'STOLEN_CARD_PICK_UP',
    label: 'Stolen card pick up',
    code: '43',
  },
  SYSTEM_ERROR: {
    name: 'SYSTEM_ERROR',
    label: 'System error',
    code: '96',
  },
  SUSPECT_FRAUD: {
    name: 'SUSPECT_FRAUD',
    label: 'Suspect fraud',
    code: '34',
  },
  SUSPECTED_FRAUD: {
    name: 'SUSPECTED_FRAUD',
    label: 'Suspected fraud',
    code: '59',
  },
  TIMEOUT_AT_ISSUER: {
    name: 'TIMEOUT_AT_ISSUER',
    label: 'Timeout at issuer',
    code: '82',
  },
  TRANSACTION_NOT_PERMITTED_TO_ACQUIRER_TERMINAL: {
    name: 'TRANSACTION_NOT_PERMITTED_TO_ACQUIRER_TERMINAL',
    label: 'Transaction not permitted to acquirer/terminal',
    code: '58',
  },
  TRANSACTION_NOT_PERMITTED_TO_ISSUER_CARDHOLDER: {
    name: 'TRANSACTION_NOT_PERMITTED_TO_ISSUER_CARDHOLDER',
    label: 'Transaction not permitted to issuer/cardholder',
    code: '57',
  },
  TRANSACTION_VIOLATES_LAW: {
    name: 'TRANSACTION_VIOLATES_LAW',
    label: 'Transaction violates law',
    code: '93',
  },
  UNABLE_TO_ROUTE_TRANSACTION: {
    name: 'UNABLE_TO_ROUTE_TRANSACTION',
    label: 'Unable to route transaction',
    code: '92',
  },
  UNACCEPTABLE_PIN_TRANSACTION_DECLINED_RETRY: {
    name: 'UNACCEPTABLE_PIN_TRANSACTION_DECLINED_RETRY',
    label: 'Unacceptable PIN - Transaction declined - Retry',
    code: '89',
  },
  SCA_SOFT_DECLINE: {
    name: 'SCA_SOFT_DECLINE',
    label: 'PSD2 SCA soft decline',
    code: '1A',
  },
};

export const ERROR_MESSAGES = {
  export: 'An error occurred while trying to download report.',
};

export const INBOUND_BANK_TRANSFER_LABEL = 'Inbound';
export const OUTBOUND_BANK_TRANSFER_LABEL = 'Outbound';

export const TRANSACTION_FILTERS_COMPACT_WINDOW_MAX_WIDTH = 1700;

export const CUSTOM_FIELD_NAMES = {
  TRANSACTION_CANCELLATION: 'transaction_cancellation',
  TRANSACTION_CHARGEBACK: 'transaction_chargeback',
  TRANSACTION_STATEMENT: 'transaction_statement',
};
