import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { map, keys, values } from 'lodash/fp';
import buildLink from 'services/buildLink';
import restrictRoutes from 'services/restrictRoutes';
import {
  PATHS,
  CLIENT_ONBOARD_STATUSES,
  NON_ONBOARDED_MERCHANT_ROUTES,
} from 'variables';
import { ChevronRight } from '@sumup/icons';
import AuthContext from 'context/auth';
import ClientDataContext from 'context/clientData';
import { useEscapeKey } from '@sumup/circuit-ui';
import {
  ModulePickerModules,
  ModulePickerWrapper,
  ModulePickerModalWrapper,
  ModuleBackdrop,
  Modules,
  Module,
  Sections,
  ArrowRight,
  ModuleName,
  ModuleArrow,
} from './ModulePickerStyled';
import {
  SectionLink,
  StyledCardIcon,
  StyledCardHeading,
  ColorCircle,
} from '../../MerchantNavigationStyled';

const ModulePicker = ({
  modules,
  activeModule,
  children,
  onboarded,
  clientId,
  isOpen,
  onClick,
  onClose,
}) => {
  const [hoveredModule, setHoveredModule] = useState(activeModule);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  useEscapeKey(() => {
    if (isOpen) {
      onClose();
    }
  });

  const isModuleHovered = (appName, hovered) =>
    (hovered.id && hovered.id === modules[appName].id) ||
    (!hovered.id && hovered.title === modules[appName].title);

  return (
    <>
      <ModulePickerWrapper>
        <ModulePickerModules>
          <StyledCardHeading noMargin inner="true" onClick={onClick}>
            <StyledCardIcon inner="true">
              {activeModule.image || (
                <ColorCircle
                  className={`module-color-${activeModule.color} small`}
                />
              )}
            </StyledCardIcon>
            {activeModule.title}
            <ArrowRight />
          </StyledCardHeading>
          {children}
        </ModulePickerModules>
        {isOpen && (
          <>
            <ModulePickerModalWrapper>
              <Modules>
                {map(
                  (appName) => (
                    <Module
                      key={appName}
                      bold
                      hovered={isModuleHovered(appName, hoveredModule)}
                      onFocus={() => setHoveredModule(modules[appName])}
                      onMouseOver={() => setHoveredModule(modules[appName])}
                    >
                      <StyledCardIcon inner="true">
                        {modules[appName].image || (
                          <ColorCircle
                            className={`module-color-${modules[appName].color} small`}
                          />
                        )}
                      </StyledCardIcon>
                      <ModuleName>{modules[appName].title}</ModuleName>
                      {isModuleHovered(appName, hoveredModule) && (
                        <ModuleArrow />
                      )}
                    </Module>
                  ),
                  keys(modules)
                )}
              </Modules>
              <Sections>
                {map(
                  ({ title, link }) => (
                    <SectionLink
                      key={title}
                      disabled={
                        !NON_ONBOARDED_MERCHANT_ROUTES(clientData)[link] &&
                        onboarded !== CLIENT_ONBOARD_STATUSES.ONBOARDED
                      }
                      to={buildLink([PATHS.client, clientId, link])}
                      onClick={onClose}
                    >
                      {title}
                      <ChevronRight />
                    </SectionLink>
                  ),
                  values(
                    restrictRoutes({
                      appName: hoveredModule,
                      status: onboarded,
                      permissions,
                      clientId,
                    })
                  )
                )}
              </Sections>
            </ModulePickerModalWrapper>
            <ModuleBackdrop onClick={onClose} />
          </>
        )}
      </ModulePickerWrapper>
    </>
  );
};

ModulePicker.defaultProps = {
  children: '',
  onboarded: '',
};

ModulePicker.propTypes = {
  modules: PropTypes.object.isRequired,
  activeModule: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object,
    color: PropTypes.string,
    sections: PropTypes.object.isRequired,
  }).isRequired,
  children: PropTypes.any,
  clientId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onboarded: PropTypes.any,
};

export default ModulePicker;
