import keyMirror from 'key-mirror-nested';

export const PATHS = keyMirror({
  actions: null,
  account: null,
  accounts: null,
  activation: null,
  activity: null,
  adjust_authorizations: null,
  authorizations: null,
  admin: null,
  apps: null,
  bank_profile: null,
  card: null,
  card_admin_backend: null,
  card_mpts: null,
  cards: null,
  challenges: null,
  cancel_authorizations: null,
  clearings: null,
  client: null,
  clients: null,
  contacts: null,
  devices: null,
  direct_debits: null,
  direct_debit_mandates: null,
  emails: null,
  events: null,
  export: null,
  'for-adjustment': null,
  fraud: null,
  bank_fraud: null,
  groups: null,
  limits: null,
  login: null,
  mass_actions: null,
  match: null,
  match_clearings: null,
  merchants: null,
  mc_report: null,
  payments: null,
  payment_requests: null,
  permissions: null,
  reconciliation: null,
  report: null,
  requests: null,
  merchant: null,
  search: null,
  scheduled_payments: null,
  soap: null,
  transaction: null,
  transactions: null,
  transfers: null,
  unmatched: null,
  user_administration: null,
  user_groups: null,
  users: null,
  users_with_groups: null,
  ops_transfers: null,
  bundling: null,
  batch: null,
});

export const ACTIVATION_ROUTES = {
  '/': true,
  [PATHS.activation]: true,
  [PATHS.client]: true,
  [PATHS.login]: true,
  [PATHS.activity]: true,
};

export const NON_ONBOARDED_MERCHANT_ROUTES = ({ client_code: clientId }) => ({
  [PATHS.activation]: true,
  [PATHS.activity]: !!clientId,
});

export const CARD_ADMIN_PATH = `/api/${PATHS.card_admin_backend}`;
export const CARD_ADMIN_V01_PATH = `/api/${PATHS.card_admin_backend}/v0.1`;
export const CARD_ADMIN_V1_PATH = `/api/${PATHS.card_admin_backend}/v1.0`;
export const CARD_ADMIN_V11_PATH = `/api/${PATHS.card_admin_backend}/v1.1`;
export const SOAP_PATH = `/api/${PATHS.soap}`;

export const SEARCH_TYPES = {
  m: PATHS.clients,
  c: PATHS.transactions,
  e: PATHS.emails,
};

export const URLS = {
  accounts: `${CARD_ADMIN_V01_PATH}/${PATHS.accounts}`,
  adminClients: `${CARD_ADMIN_V01_PATH}/${PATHS.admin}/${PATHS.clients}`,
  adminTransfers: `${CARD_ADMIN_V01_PATH}/${PATHS.admin}/${PATHS.transfers}`,
  apps: `${CARD_ADMIN_V01_PATH}/${PATHS.apps}`,
  authorizations: `${CARD_ADMIN_PATH}/${PATHS.authorizations}`,
  bankProfile: `${CARD_ADMIN_V1_PATH}/${PATHS.bank_profile}`,
  card: `${CARD_ADMIN_V01_PATH}/${PATHS.cards}`,
  cardAdmin: `${CARD_ADMIN_V01_PATH}/card-admin`,
  clients: `${CARD_ADMIN_V01_PATH}/${PATHS.clients}`,
  clientsV1: `${CARD_ADMIN_V1_PATH}/${PATHS.clients}`,
  contacts: `${CARD_ADMIN_V01_PATH}/${PATHS.contacts}`,
  directDebitMandates: `${CARD_ADMIN_V01_PATH}/${PATHS.direct_debit_mandates}`,
  directDebits: `${CARD_ADMIN_V01_PATH}/${PATHS.direct_debits}`,
  events: `${CARD_ADMIN_V01_PATH}/${PATHS.events}`,
  fraud: `${CARD_ADMIN_V01_PATH}/${PATHS.fraud}`,
  bank_fraud: `${CARD_ADMIN_V01_PATH}/${PATHS.bank_fraud}`,
  gasAccounts: `${CARD_ADMIN_V1_PATH}/${PATHS.accounts}`,
  groups: `${CARD_ADMIN_V01_PATH}/${PATHS.groups}`,
  logout: `${CARD_ADMIN_PATH}/logout`,
  merchants: `${CARD_ADMIN_V01_PATH}/${PATHS.merchants}`,
  oauth: `${CARD_ADMIN_PATH}/oauth/login/google`,
  payments: `${CARD_ADMIN_V01_PATH}/${PATHS.payments}`,
  paymentRequests: `${CARD_ADMIN_V01_PATH}/${PATHS.payment_requests}`,
  permissions: `${CARD_ADMIN_V01_PATH}/${PATHS.permissions}`,
  risk_profile: `${CARD_ADMIN_V01_PATH}/${PATHS.merchant}`,
  transactions: `${CARD_ADMIN_V01_PATH}/${PATHS.transactions}`,
  transactionsV1: `${CARD_ADMIN_V1_PATH}/${PATHS.transactions}`,
  transactionsV11: `${CARD_ADMIN_V11_PATH}/${PATHS.transactions}`,
  search: `${CARD_ADMIN_V01_PATH}/${PATHS.search}`,
  soap: `${SOAP_PATH}/${PATHS.users}`,
  users: `${CARD_ADMIN_V01_PATH}/${PATHS.users}`,
  export: `${CARD_ADMIN_V01_PATH}/${PATHS.export}`,
  reconciliation: `${CARD_ADMIN_PATH}/${PATHS.reconciliation}`,
  opsTransfers: `${CARD_ADMIN_V01_PATH}/${PATHS.ops_transfers}`,
};

export const DATE_FORMATS = {
  MONTH_DAY_YEAR: 'MM/DD/YYYY',
  SHORT_MONTH_DAY_YEAR_SHORT_TIME: 'MMM DD, YYYY HH:mm',
  SHORT_MONTH_DAY_YEAR_TIME: 'MMM DD, YYYY HH:mm:ss',
  SHORT_MONTH_DAY_YEAR: 'MMM DD, YYYY',
  YEAR_SHORT_MONTH_DAY: 'YYYY-MM-DD',
  YEAR_SHORT_MONTH_DAY_TIME: 'YYYY-MM-DDTHH:mm:ss',
  DAY_OF_WEEK_DAY_MONTH: 'dddd, D MMMM',
};

export const DATE_PERIOD_CUSTOM = 'custom';
export const DATE_PERIOD_YESTERDAY = 'yesterday';
export const DATE_PERIOD_TWO_DAYS = 'two-days';
export const DATE_PERIOD_FIVE_DAYS = 'five-days';
export const DATE_PERIOD_WEEK = 'week';
export const DATE_PERIOD_MONTH = 'month';
export const DATE_PERIOD_QUARTER = 'quarter';
export const DATE_PERIOD_HALF_YEAR = 'half-year';
export const DATE_PERIOD_YEAR = 'year';
export const ONE_MONTH = 30;
export const ONE_WEEK = 7;

export const DEBOUNCE_TIMEOUT_LONG = 500;
export const DEBOUNCE_TIMEOUT_SHORT = 50;

export const APP_TITLE = 'Bank Backoffice';

export const PAGE_TITLES = {
  LOGIN: {
    title: `Login | ${APP_TITLE}`,
    heading: APP_TITLE,
  },
  HOME: {
    title: APP_TITLE,
    heading: APP_TITLE,
  },
  ADMIN: {
    title: APP_TITLE,
    heading: APP_TITLE,
  },
  CLIENT: {
    title: `Client details | ${APP_TITLE}`,
    heading: 'Merchant Details',
  },
  TRANSACTION: {
    title: `Transaction details | ${APP_TITLE}`,
    heading: 'Transaction details',
  },
  TRANSACTIONS: {
    title: `Transactions | ${APP_TITLE}`,
    heading: 'Transactions',
  },
  ACTIVATION: {
    title: `Activation | ${APP_TITLE}`,
    heading: 'Activation',
  },
  ACTIVITY_LOG: {
    title: `Activity log | ${APP_TITLE}`,
    heading: 'Activity log',
  },
  DEVICES: {
    title: `SCA devices | ${APP_TITLE}`,
    heading: 'SCA devices',
  },
  CHALLENGES: {
    title: `PSD challenges | ${APP_TITLE}`,
    heading: 'PSD challenges',
  },
  ACCOUNT: {
    title: `Overview | ${APP_TITLE}`,
    heading: 'Overview',
  },
  LIMITS: {
    title: `Limits | ${APP_TITLE}`,
    heading: 'Limits',
  },
  USER_ADMINISTRATION: {
    title: `User administration | ${APP_TITLE}`,
    heading: 'User Administration',
  },
  RECONCILIATION: {
    title: `Card Reconciliation | ${APP_TITLE}`,
    heading: 'Card Reconciliation',
  },
  REPORTS: {
    title: `Reports | ${APP_TITLE}`,
    heading: 'Reports',
  },
  MASS_ACTIONS: {
    title: `Mass actions | ${APP_TITLE}`,
    heading: 'Mass Actions',
  },
  DIRECT_DEBITS: {
    title: `Direct debits | ${APP_TITLE}`,
    heading: 'Direct debits',
  },
  OPS_TRANSFERS: {
    title: `Operational transfers | ${APP_TITLE}`,
    heading: 'Operational transfers',
    requestsPage: 'Requests',
  },
  NBA_BUNDLING: {
    title: `Bundling | ${APP_TITLE}`,
    heading: 'Bundling',
  },
  NBA_BUNDLING_BATCH_ENROLMENT: {
    title: `Batch Enrolment`,
    heading: 'Batch Enrolment',
  },
  SCHEDULED_PAYMENTS: {
    title: `Scheduled payments | ${APP_TITLE}`,
    heading: 'Scheduled payments',
  },
};

export const ERROR_MESSAGE = 'Something went wrong';

export const HTTP_STATUS_CODES = {
  OK: 200,
  NO_CONTENT: 204,
};

export const SORT_ORDER_ASCENDING = 'asc';
export const SORT_ORDER_DESCENDING = 'desc';
export const WRAPPER_WIDTH = 1680;
export const MODULE_PICKER_WIDTH = 481;

export const WALLETS = {
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
};

export const CARD_TYPES = keyMirror({
  virtual: null,
  physical: null,
});

export const WALLET_TOKEN_STATUSES = {
  ACTIVE: {
    label: 'Active',
    action: 'Resume',
  },
  SUSPENDED: {
    label: 'Suspended',
    action: 'Suspend',
  },
  DEACTIVATED: {
    label: 'Deactivated',
    action: 'Deactivate',
  },
};

export const EN_GB_LOCALE = 'en-GB';
export const DEBOUNCE_DELAY = 200;
export const CURRENT_ADDRESS = 'current';
export const CUSTOM_ADDRESS = 'custom';
export const VALIDATION_PATTERNS = {
  CARD_ADMIN_USER_EMAIL_REGEX: /@sumup.com$/,
};

export const ROLES = keyMirror({
  ROLE_ADMIN: null,
  ROLE_VIEWER: null,
  ROLE_FRAUD_AGENT: null,
});

export const LOGGED_USER_COOKIE = 'logged-user';
export const TRANSACTIONS_DATE_PERIOD_COOKIE = 'txs_date_period';
export const TRANSACTIONS_PAGE_SIZE_COOKIE = 'txs_page_size';
export const CLEARINGS_DATE_PERIOD_COOKIE = 'clearings_date_period';
export const CLEARINGS_PAGE_SIZE_COOKIE = 'clearings_page_size';
export const AUTHORIZATIONS_DATE_PERIOD_COOKIE = 'authorizations_date_period';
export const AUTHORIZATIONS_PAGE_SIZE_COOKIE = 'authorizations_page_size';
export const CANCEL_AUTHORIZATIONS_DATE_FILTER_COOKIE =
  'cancel_authorizations_date_filter';
export const CANCEL_AUTHORIZATIONS_PAGE_SIZE_COOKIE =
  'cancel_authorizations_page_size';
export const REDIRECT_URI = 'redirect_uri';

export const SESSION_EXPIRED_QUERY_PARAM = 'sessionExpired';

export const FILE_EXTENSIONS = {
  CSV: 'csv',
  PDF: 'pdf',
};

export const SUMUP_BANK_CODE = '040059';

export const SUMUP_SWIFT_CODES = keyMirror({
  SRLGGB2L: null,
  SUMUIE22: null,
  SUPULT22: null,
  SRLGGB2LXXX: null,
  SUMUIE22XXX: null,
  SUPULT22XXX: null,
});

export const RESPONSE_STATUS_FAILED = 'FAILED';

export const APPLE_PAY_WALLET_ID = '103';
export const GOOGLE_PAY_WALLET_ID = '216';

export const PAYOUT_INSTRUMENT_MAP = {
  bank_account: 'Third-party bank account',
  prepaid_card: 'SumUp Business Account',
  balance: 'Balance',
};

export const CARD_INTEGRATOR_SUMUP_CARD = 'SumUp Card';
export const CARD_INTEGRATOR_CONSUMER = 'Consumer';

export const CLEARING_TYPE_MATCH = 'MATCH';
export const CLEARING_TYPE_OFFLINE_TRANSACTION = 'OFFLINE_TRANSACTION';
export const CONSUMER_ID_PREFIX = 'C';

export const AUTH_CODE_QUERY_PARAM = 'auth_code';
export const RRN_QUERY_PARAM = 'rrn';

export const CURRENCY_CODES = {
  GBP: 'GBP',
  EUR: 'EUR',
};

export const CURRENCY_SYMBOLS = {
  [CURRENCY_CODES.EUR]: '€',
  [CURRENCY_CODES.GBP]: '£',
};

export const DEFAULT_CURRENCY_EXPONENT = 2;
export const DEFAULT_FRACTION_DIGITS = 2;

export const MULTIPLE_CARDS_TYPES = keyMirror({
  primary: null,
  replacement: null,
});
