import { actionTypes } from 'variables';

export const setCardLimits = (cardLimits) => ({
  type: actionTypes.SET_CARD_LIMITS,
  payload: {
    cardLimits,
  },
});

export const setLimitsLoading = (loading) => ({
  type: actionTypes.SET_LIMITS_LOADING,
  payload: { loading },
});

export const loadLimitsSuccess = (data) => ({
  type: actionTypes.LOAD_LIMITS_SUCCESS,
  payload: data,
});
