import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Body,
  Button,
  Card,
  Popover,
  Spinner,
  useModal,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { formatCurrency } from '@sumup/intl';
import { capitalize } from 'lodash';
import moment from 'moment';
import {
  EN_GB_LOCALE,
  MODALS,
  COUNTRIES,
  ACTIONS,
  APPS,
  COUNTRY_NAMES,
  PAYOUT_INSTRUMENT_MAP,
  DATE_FORMATS,
  CARD_INTEGRATOR_SUMUP_CARD,
  CARD_INTEGRATOR_CONSUMER,
} from 'variables';
import Modal from 'components/Modal';
import Error from 'components/Error';
import { useHttpClient } from 'hooks';
import { transferToBankEnabled } from 'services/clientData';
import { ReactComponent as Bank } from 'assets/bank.svg';
import { ReactComponent as RequestTransfer } from 'assets/request-transfer.svg';
import Empty from 'components/Empty';
import { PopoverOption } from 'components/UserAdministration/components/DetailsSection/DetailsSectionStyled';
import { createUpdateOpsTransfer } from 'api';
import {
  StyledHeading,
  DividedBlock,
  StyledLabel,
  StyledText,
  DividedBlockColumn,
  Inactive,
  Block50,
  Block25,
  Balance,
  Row,
  Split,
  StyledLoadingButton,
  BalanceHeading,
} from '../../AccountStyled';
import AccountStatus from './components/AccountStatus';
import AccountPopover from './components/AccountPopover';
import TransferToBankModal from './components/TransferToBankModal';
import RequestTransferModal from './components/RequestTransferModal';

const TransferButton = styled(Button)`
  display: flex;
  align-items: center;
  max-height: 36px;
`;

const StyledPopoverOption = styled(PopoverOption)`
  display: flex;
  align-items: center;

  svg {
    min-width: 24px;
  }
`;

const { accountcardOverviewTransfer, accountcardOverviewRequestTransfer } =
  ACTIONS[APPS.merchant];

const BusinessAccount = ({
  accountData: {
    iban,
    bank_account_number: accountNumber,
    bank_account_holder: accountHolder,
    bank_account_identifier: accountId,
    sort_code: sortCode,
    balance = {},
    currency = {},
    can_create_bank_account: canCreateBankAccount,
    merchant_profile: merchantProfile,
    bank_account_issue_date: accountCreationDate,
    card_integrator: cardIntegrator,
    ...accountData
  },
  loading,
  error,
  balanceLoading,
  transferToBankLoading,
  accountStatus,
  accountStatusLoading,
  hasActionAccess,
  onCreateBankAccount,
  onTransferToBank,
  onAccountStatusChange,
  isSubBalance,
}) => {
  const [transferPopoverOpen, setTransferPopoverOpen] = useState(false);
  const [transferToThirdPartyBankEnabled, setTransferToThirdPartyBankEnabled] =
    useState(false);
  const httpClient = useHttpClient();
  const { clientId } = useParams();

  useEffect(() => {
    transferToBankEnabled({
      httpClient,
      clientId,
    }).then(setTransferToThirdPartyBankEnabled);
  }, []);

  const country = merchantProfile?.country || accountData?.country;
  const ukMerchant = country === COUNTRIES.GB;

  const { setModal, removeModal } = useModal();

  const handleCreateBankAccount = () => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            ...MODALS.CREATE_BUSINESS_ACCOUNT,
            onConfirm: onCreateBankAccount,
          }}
          onClose={onClose}
        />
      ),
      onClose: () => removeModal,
      closeButtonLabel: 'Close',
    });
  };

  const { balance: nonNegativeBalance, negative_balance: negativeBalance } =
    balance;

  const { code: currencyCode, exponent } = currency;

  const formattedBalance = `${negativeBalance ? '-' : ''}${formatCurrency(
    (negativeBalance || nonNegativeBalance) / 10 ** exponent,
    EN_GB_LOCALE,
    currencyCode
  )}`;

  const handleTransferButtonClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    setTransferPopoverOpen(true);
  };

  const transferPopoverComponent = () => (
    <TransferButton
      isLoading={transferToBankLoading}
      loadingLabel="Loading"
      variant="primary"
      onClick={handleTransferButtonClick}
    >
      Transfer
    </TransferButton>
  );

  const thirdPartyTransferOptionEnabled =
    transferToThirdPartyBankEnabled && nonNegativeBalance && !negativeBalance;
  const thirdPartyTransferOption = (
    <StyledPopoverOption key="transfer-to-third-party-bank-option">
      <Bank />
      <Body noMargin size="one">
        Transfer to third party account
      </Body>
    </StyledPopoverOption>
  );

  const hasTransferToBankPermissions = hasActionAccess({
    action: accountcardOverviewTransfer,
  });

  const hasRequestTransferPermissions = hasActionAccess({
    action: accountcardOverviewRequestTransfer,
  });

  let transferPopoverActions = [];

  if (
    hasTransferToBankPermissions &&
    thirdPartyTransferOptionEnabled &&
    cardIntegrator === CARD_INTEGRATOR_SUMUP_CARD
  ) {
    transferPopoverActions = [
      ...transferPopoverActions,
      {
        children: thirdPartyTransferOption,
        onClick: () =>
          setModal({
            closeButtonLabel: 'Close',
            onClose: () => {},
            // eslint-disable-next-line react/prop-types
            children: ({ onClose }) => (
              <TransferToBankModal
                fullAmount={nonNegativeBalance}
                formattedFullAmount={formattedBalance}
                onTransfer={onTransferToBank}
                onClose={onClose}
              />
            ),
          }),
      },
    ];
  }

  if (hasRequestTransferPermissions) {
    const ukTransfer = country === 'GB';

    transferPopoverActions = [
      ...transferPopoverActions,
      {
        children: (
          <StyledPopoverOption key="request-a-transfer-option">
            <RequestTransfer />
            <Body noMargin size="one">
              Request a transfer
            </Body>
          </StyledPopoverOption>
        ),
        onClick: () =>
          setModal({
            closeButtonLabel: 'Close',
            children: ({ onClose }) => (
              <RequestTransferModal
                ukTransfer={ukTransfer}
                balance={nonNegativeBalance}
                accountId={accountId}
                exponent={exponent}
                currency={currency?.code}
                submitSuccessNotificationHeadline="Transfer requested successfully"
                submitErrorNotificationHeadline="Transfer request unsuccessful"
                submitErrorNotificationBody="Please try again later."
                submitButtonLabel="Request"
                onSubmit={createUpdateOpsTransfer}
                onClose={onClose}
              />
            ),
          }),
      },
    ];
  }

  const { settings = {} } = merchantProfile || {};
  const { payout_instrument: payoutInstrument, payout_period: payoutPeriod } =
    settings;

  return (
    <>
      {error && <Error>{error}</Error>}
      <Row>
        <Block50>
          <Split>
            <StyledHeading as="h2" size="two" noMargin>
              Business account
            </StyledHeading>
            {canCreateBankAccount &&
              !iban &&
              hasActionAccess({
                action: ACTIONS[APPS.merchant].accountcardOverviewIssueAccount,
              }) && (
                <StyledLoadingButton
                  variant="primary"
                  isLoading={loading}
                  loadingLabel="Loading"
                  onClick={handleCreateBankAccount}
                >
                  Create account
                </StyledLoadingButton>
              )}
          </Split>
          {iban ? (
            <Card spacing="0">
              <DividedBlock>
                <DividedBlockColumn>
                  <StyledLabel noMargin size="two">
                    CREATED ON:
                  </StyledLabel>
                  <StyledText noMargin>
                    {accountCreationDate ? (
                      moment(accountCreationDate).format(
                        DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                      )
                    ) : (
                      <Empty>—</Empty>
                    )}
                  </StyledText>
                </DividedBlockColumn>
                <DividedBlockColumn>
                  <StyledLabel noMargin size="two">
                    ACCOUNT HOLDER:
                  </StyledLabel>
                  <StyledText noMargin>
                    {accountHolder || <Empty>—</Empty>}
                  </StyledText>
                </DividedBlockColumn>
                {(ukMerchant && accountNumber && sortCode && (
                  <>
                    <DividedBlockColumn>
                      <StyledLabel noMargin size="two">
                        ACCOUNT NUMBER:
                      </StyledLabel>
                      <StyledText noMargin>{accountNumber}</StyledText>
                    </DividedBlockColumn>
                    <DividedBlockColumn>
                      <StyledLabel noMargin size="two">
                        SORT CODE:
                      </StyledLabel>{' '}
                      <StyledText noMargin>{sortCode}</StyledText>
                    </DividedBlockColumn>
                  </>
                )) || (
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      IBAN:
                    </StyledLabel>
                    <StyledText noMargin>{iban}</StyledText>
                  </DividedBlockColumn>
                )}
                <AccountStatus
                  accountStatus={accountStatus}
                  loading={accountStatusLoading}
                  hasActionAccess={hasActionAccess}
                  onStatusChange={onAccountStatusChange}
                />
                <AccountPopover accountId={accountId} />
              </DividedBlock>
            </Card>
          ) : (
            <Inactive>
              {canCreateBankAccount &&
                !isSubBalance &&
                'This merchant does not have a business account.'}
              {!canCreateBankAccount &&
                !isSubBalance &&
                `Business account is not available in ${
                  COUNTRY_NAMES[country] || 'this country'
                } yet.`}
              {isSubBalance && 'This balance does not have a business account.'}
            </Inactive>
          )}
        </Block50>
        {!isSubBalance && (
          <Block25>
            <StyledHeading as="h2" size="two" noMargin>
              Payouts
            </StyledHeading>
            <Card spacing="0">
              <DividedBlock>
                <DividedBlockColumn>
                  <StyledLabel noMargin size="two">
                    INSTRUMENT:
                  </StyledLabel>
                  <StyledText noMargin>
                    {payoutInstrument ? (
                      PAYOUT_INSTRUMENT_MAP[payoutInstrument]
                    ) : (
                      <Empty>—</Empty>
                    )}
                  </StyledText>
                </DividedBlockColumn>
                <DividedBlockColumn>
                  <StyledLabel noMargin size="two">
                    FREQUENCY:
                  </StyledLabel>
                  <StyledText noMargin>
                    {payoutPeriod ? capitalize(payoutPeriod) : <Empty>—</Empty>}
                  </StyledText>
                </DividedBlockColumn>
              </DividedBlock>
            </Card>
          </Block25>
        )}
        <Block25>
          <BalanceHeading>
            <StyledHeading as="h2" size="two" noMargin variant="highlight">
              Balance
            </StyledHeading>
            {(cardIntegrator === CARD_INTEGRATOR_SUMUP_CARD ||
              cardIntegrator === CARD_INTEGRATOR_CONSUMER) &&
              transferPopoverActions?.length > 0 &&
              (hasTransferToBankPermissions || hasRequestTransferPermissions) &&
              !isSubBalance && (
                <div>
                  <Popover
                    isOpen={transferPopoverOpen}
                    placement="bottom-end"
                    actions={transferPopoverActions}
                    component={transferPopoverComponent}
                    onToggle={() =>
                      setTransferPopoverOpen(!transferPopoverOpen)
                    }
                  />
                </div>
              )}
          </BalanceHeading>
          <Balance
            spacing="0"
            negativeBalance={negativeBalance}
            nonNegativeBalance={nonNegativeBalance}
          >
            <StyledLabel noMargin size="two">
              CURRENT AMOUNT:
            </StyledLabel>
            {balanceLoading ? (
              <Spinner />
            ) : (
              <StyledText noMargin>{formattedBalance}</StyledText>
            )}
          </Balance>
        </Block25>
      </Row>
    </>
  );
};

BusinessAccount.defaultProps = {
  loading: false,
  balanceLoading: false,
  transferToBankLoading: false,
  accountStatusLoading: true,
  error: null,
  accountStatus: null,
};

BusinessAccount.propTypes = {
  accountData: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  balanceLoading: PropTypes.bool,
  transferToBankLoading: PropTypes.bool,
  error: PropTypes.string,
  accountStatus: PropTypes.string,
  accountStatusLoading: PropTypes.bool,
  hasActionAccess: PropTypes.func.isRequired,
  onCreateBankAccount: PropTypes.func.isRequired,
  onTransferToBank: PropTypes.func.isRequired,
  onAccountStatusChange: PropTypes.func.isRequired,
  isSubBalance: PropTypes.bool.isRequired,
};

export default BusinessAccount;
