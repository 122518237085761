import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Headline, Select, spacing } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { keys, map } from 'lodash';
import { ERROR_MESSAGE, LANGUAGE_NAMES } from 'variables';
import Error from 'components/Error';

const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  h1 {
    align-items: center;
    text-align: center;
  }
`;

const Body = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    margin-top: ${theme.spacings.giga};

    > label {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div {
        width: 100%;
      }
    }
  `
);

const Footer = styled('div')`
  display: flex;
  justify-content: center;

  > div {
    justify-content: center;
  }
`;

const DownloadStatementModal = ({ onConfirm, onClose }) => {
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [error, setError] = useState(null);

  const languageRef = useRef({});

  const handleLanguageChange = ({ target: { value } }) => {
    languageRef.current = value;
  };

  return (
    <ModalWrapper>
      <Headline as="h1" size="three" noMargin>
        Select language
      </Headline>
      <Body>
        <Select
          css={spacing({ bottom: 'byte' })}
          placeholder="—"
          label="Language"
          noMargin
          onChange={handleLanguageChange}
        >
          {map(keys(LANGUAGE_NAMES), (language) => (
            <option key={language} value={language}>
              {language}
            </option>
          ))}
        </Select>
      </Body>
      {error && <Error>{error}</Error>}
      <Footer>
        <ButtonGroup
          actions={{
            primary: {
              loadingLabel: 'Loading',
              isLoading: confirmButtonLoading,
              onClick: (e) => {
                setConfirmButtonLoading(true);

                onConfirm(languageRef?.current)
                  .then(() => {
                    setError(null);
                    setConfirmButtonLoading(false);
                    onClose(e);
                  })
                  .catch(() => {
                    setError(ERROR_MESSAGE);
                    setConfirmButtonLoading(false);
                  });
              },
              children: 'Download statement',
            },
            secondary: {
              children: 'Cancel',
              onClick: onClose,
            },
          }}
        />
      </Footer>
    </ModalWrapper>
  );
};

DownloadStatementModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DownloadStatementModal;
