export const initialState = {
  accountDataLoading: true,
  issueCardLoading: false,
  replaceCardLoading: false,
  createBankAccountLoading: false,
  createBankAccountError: null,
  primaryPsdStatusLoading: false,
  replacementPsdStatusLoading: false,
  lockCardForAuditLoading: false,
  primaryCardStatusLoading: false,
  replacementCardStatusLoading: false,
  transferToBankLoading: false,
  transferToBankError: null,
  balanceLoading: false,
  accountStatusLoading: true,
  accountStatusError: null,
  activeCards: [],
  accountContractStatus: '',
};
