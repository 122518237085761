import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { PATHS, DATA_SELECTORS } from 'variables';
import buildLink from 'services/buildLink';
import SearchResultsItem from './SearchResultsItem';
import { StyledSearchResults } from './StyledSearchResults';

const SearchResults = ({ results, onResultClick }) => (
  <StyledSearchResults data-testid={DATA_SELECTORS.SEARCH_RESULTS}>
    {map(
      results,
      ({
        email,
        first_name: firstName,
        last_name: lastName,
        merchant_code: merchantCode,
      }) => {
        const name = `${firstName} ${lastName}`;
        const link = buildLink([PATHS.client, merchantCode]);
        return (
          <SearchResultsItem
            onResultClick={onResultClick}
            key={email}
            {...((firstName || lastName) && { name })}
            merchantCode={merchantCode}
            email={email}
            link={link}
          />
        );
      }
    )}
  </StyledSearchResults>
);

SearchResults.propTypes = {
  results: PropTypes.array.isRequired,
  onResultClick: PropTypes.func.isRequired,
};

export default SearchResults;
