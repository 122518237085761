import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import { getMerchantPageData } from 'services/merchantPageData';
import { onboardClient as onboardClientApiCall } from 'api';
import { loadError, loadSuccess, setLoading } from 'actions';
import ClientDataContext from 'context/clientData';
import { useModal } from '@sumup/circuit-ui';
import { CARD_TYPES } from 'variables';
import MerchantPage from './MerchantPage';
import { merchantPageDataReducer } from './merchantPageDataReducer';
import IssueCardModal from '../Account/components/ActiveCards/components/IssueCardModal';

const initialState = {
  clientDataLoading: true,
  clientData: {},
  externalLinks: {},
};

const PageData = (props) => {
  const { clientId, accountId } = useParams();
  const httpClient = useHttpClient();
  const { setModal } = useModal();

  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () => getMerchantPageData(httpClient, { clientId, accountId }),
      [httpClient, clientId, accountId]
    ),
    null,
    merchantPageDataReducer,
    initialState
  );

  const handleIssueCard = ({ virtualCardSelected, newAddress }) => {
    dispatch(setLoading(true));

    let shippingAddress = {};
    const {
      clientData: { personal_profile: profile = {}, account = {} },
    } = state;
    const {
      first_name: firstName,
      last_name: lastName,
      mobile_phone: mobilePhone,
      address = {},
    } = profile;
    const { username: email } = account;
    const { country } = address;

    if (newAddress) {
      shippingAddress = {
        addressLine1: newAddress.address_line_1,
        addressLine2: newAddress.address_line_2,
        city: newAddress.city,
        postCode: newAddress.post_code,
      };
    } else {
      shippingAddress = {
        addressLine1: address.address_line1,
        addressLine2: address.address_line_2,
        city: address.city,
        postCode: address.post_code,
      };
    }

    const { city, addressLine1, addressLine2, postCode } = shippingAddress;

    return onboardClientApiCall({
      email,
      city,
      clientId,
      firstName,
      lastName,
      mobilePhone,
      country,
      addressLine1,
      addressLine2,
      postCode,
      httpClient,
      cardType: virtualCardSelected ? CARD_TYPES.virtual : CARD_TYPES.physical,
    })
      .then(() => {
        return getMerchantPageData(httpClient, { clientId });
      })
      .then((data) => dispatch(loadSuccess(data)))
      .catch((error) => dispatch(loadError(error)));
  };

  const onboardClient = () => {
    const {
      clientData: { personal_profile: profile = {} },
    } = state;

    const {
      first_name: firstName,
      last_name: lastName,
      address = {},
    } = profile;

    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <IssueCardModal
          address={{ ...address, address_line_1: address.address_line1 }}
          personalProfile={{ first_name: firstName, last_name: lastName }}
          onClose={onClose}
          onIssueCard={handleIssueCard}
        />
      ),
      closeButtonLabel: 'Close',
      onClose: () => {},
    });
  };

  return (
    <ClientDataContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <MerchantPage {...props} onboardClient={onboardClient} />
    </ClientDataContext.Provider>
  );
};

export default PageData;
