export const LIMIT_PAYMENT_TYPE_ALL = 'all';
export const LIMIT_PAYMENT_TYPE_CASH = 'cash';
export const LIMIT_PAYMENT_TYPE_ATM = 'atm';
export const LIMIT_PAYMENT_TYPE_ATM_BALANCE = 'atm balance inquiry';
export const LIMIT_PAYMENT_TYPE_ATM_CHANGE_PIN = 'atm change pin';
export const LIMIT_PAYMENT_TYPE_CASHBACK = 'cashback';
export const LIMIT_PAYMENT_TYPE_NON_CASH = 'non_cash';
export const LIMIT_PAYMENT_TYPE_POS = 'pos';
export const LIMIT_PAYMENT_TYPE_CONTACTLESS = 'contactless';
export const LIMIT_PAYMENT_TYPE_CNP = 'cnp';

export const CARD_LIMITS_HEADING = ({ cardId }) => `Limits for card ${cardId}`;
export const BANK_LIMITS_HEADING = 'Bank limits';

export const HIERARCHY_LEVEL_SPACING = {
  1: '0px',
  2: '16px',
  3: '32px',
};

export const LIMIT_PAYMENT_TYPE_LABEL = {
  [LIMIT_PAYMENT_TYPE_ALL]: 'All',
  [LIMIT_PAYMENT_TYPE_CASH]: 'Cash',
  [LIMIT_PAYMENT_TYPE_ATM]: 'ATM',
  [LIMIT_PAYMENT_TYPE_ATM_BALANCE]: 'ATM balance',
  [LIMIT_PAYMENT_TYPE_ATM_CHANGE_PIN]: 'ATM change PIN',
  [LIMIT_PAYMENT_TYPE_CASHBACK]: 'Cashback',
  [LIMIT_PAYMENT_TYPE_NON_CASH]: 'Non cash',
  [LIMIT_PAYMENT_TYPE_POS]: 'POS',
  [LIMIT_PAYMENT_TYPE_CONTACTLESS]: 'Contactless',
  [LIMIT_PAYMENT_TYPE_CNP]: 'CNP',
};

export const LIMIT_TOOLTIP_CONTENT_TEST_ID = 'limit-tooltip-content';
export const CARD_LIMITS_LOAD_ERROR = `Couldn't load card limits`;
export const BANK_LIMITS_LOAD_ERROR = `Couldn't load bank limits`;
export const CARD_LIMITS_EDIT_ERROR = `Couldn't save limit`;
export const CARD_LIMITS_EDIT_SUCCESS = 'Limit saved successfully';
export const CANCEL_CHANGES_LOADING = 'Cancelling changes...';
export const SAVE_CHANGES_LOADING = 'Saving changes...';
export const TRY_AGAIN_MESSAGE = 'Please try again.';
