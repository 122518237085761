import { MODULES, SUBBALANCE_MODULE, PATHS } from 'variables';
import { cloneDeep, keys, compact, find, difference, flatten } from 'lodash/fp';
import { getSubBalances } from './globalAccountsService';

const merchantCodeRegex = new RegExp(`/${PATHS.client}/.+?(?=/)/`, 'i');

const clearFromPath = (path, values) =>
  difference(path.split('/'), values).join('/');

const buildSections = (id) => {
  const sections = cloneDeep(SUBBALANCE_MODULE.sections);
  keys(sections).forEach((key) => {
    sections[key].link = sections[key].link.replace(':id', id);
  });
  return sections;
};

export const buildModules = ({ gasAccounts = [], balance }) => {
  const result = { ...MODULES };
  const subbalances = getSubBalances(gasAccounts || []);

  if (balance) {
    result.MAIN_BALANCE.balance = balance;
  }

  subbalances.forEach((subbalance, index) => {
    result[`SUBBALANCE_${index}`] = {
      ...SUBBALANCE_MODULE,
      id: subbalance.id,
      title: subbalance.name,
      balance: subbalance.balance,
      color: subbalance.metadata.color || 'blue',
      sections: buildSections(subbalance.id),
    };
  });

  return result;
};

const getModuleByPath = (path, modules) =>
  find(
    (module) =>
      find(
        (key) => module.sections[key].link === path.split('/')[0],
        keys(module.sections)
      ),
    modules
  );

export const findModuleByPath = ({ path, modules }) => {
  const clearedPath = path.replace(merchantCodeRegex, '');

  const subbalanceIds = compact(
    Object.values(modules).map((module) => module.id)
  );
  const activeSubbalanceId = subbalanceIds.find((id) =>
    clearedPath.includes(id)
  );

  if (activeSubbalanceId) {
    return Object.values(modules).find((m) => m.id === activeSubbalanceId);
  }

  return getModuleByPath(clearedPath, modules) || modules.GENERAL;
};

export const moduleSectionMatches = ({ path, params = {}, section }) => {
  const clearedPath = clearFromPath(
    path,
    flatten([PATHS.client, Object.values(params)])
  );
  const sectionPath = clearFromPath(section.link, Object.values(params));

  return clearedPath === `/${sectionPath}`;
};
