import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import MerchantNavigation from 'components/MerchantNavigation';
import Loading from 'components/Loading';
import { CLIENT_ONBOARD_STATUSES, ACTIVATION_ROUTES, PATHS } from 'variables';
import buildLink from 'services/buildLink';
import ClientDataContext from 'context/clientData';
import { PageComponentWrapper } from '../Page/PageStyled';

const Page = ({
  title,
  component: PageComponent,
  render,
  componentStyle,
  heading,
  link,
  sections,
  onboardClient,
}) => {
  const {
    state: {
      clientData: { onboarding_status: onboarding },
      clientDataLoading,
    },
  } = useContext(ClientDataContext);

  const { clientId } = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = title;
  });

  const redirectToActivation =
    !ACTIVATION_ROUTES[render] &&
    onboarding !== CLIENT_ONBOARD_STATUSES.ONBOARDED;

  const redirectToClient =
    onboarding === CLIENT_ONBOARD_STATUSES.ONBOARDED &&
    render === PATHS.activation;

  return (
    <>
      {render !== '/' && render !== PATHS.login && (
        <MerchantNavigation
          render={render}
          heading={heading}
          link={link}
          sections={sections}
        />
      )}
      {clientDataLoading ? (
        <Loading />
      ) : (
        <PageComponentWrapper style={componentStyle}>
          {(redirectToActivation &&
            history.push(
              buildLink([PATHS.client, clientId, PATHS.activation])
            )) ||
            (redirectToClient &&
              history.push(buildLink([PATHS.client, clientId]))) || (
              <PageComponent onboardClient={onboardClient} />
            )}
        </PageComponentWrapper>
      )}
    </>
  );
};

Page.defaultProps = {
  component: () => null,
  componentStyle: {},
  onboardClient: () => {},
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.func,
  render: PropTypes.string.isRequired,
  componentStyle: PropTypes.object,
  heading: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  sections: PropTypes.object.isRequired,
  onboardClient: PropTypes.func,
};

export default Page;
