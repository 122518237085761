import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useReducer,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Body,
  Button,
  Headline,
  spacing,
  useNotificationToast,
} from '@sumup/circuit-ui';
import { useHttpClient } from 'hooks';
import { map, values } from 'lodash/fp';
import { getCardLimits, saveCardLimits } from 'api';
import Loading from 'components/Loading';
import { ACTIONS, APPS, COUNTRY_NAMES } from 'variables';
import AuthContext from 'context/auth';
import { hasActionAccess } from 'services/permissions';
import { getClientDetailsWithLimits } from 'services/clientData';
import styled from '@emotion/styled';
import ClientDataContext from 'context/clientData';
import Error from 'components/Error';
import { loadError } from 'actions';
import { ExternalLink } from '@sumup/icons';
import { Inactive } from 'components/Account/AccountStyled';
import DataGrid from '../DataGrid';
import {
  editableCardLimitsColumns,
  cardLimitsColumns as initialCardLimitsColumns,
} from './limitsColumns';
import { limitsReducer } from './limitsReducer';
import {
  setCardLimits,
  setLimitsLoading,
  loadLimitsSuccess,
} from './limitsActions';
import {
  BANK_LIMITS_HEADING,
  CANCEL_CHANGES_LOADING,
  CARD_LIMITS_EDIT_ERROR,
  CARD_LIMITS_EDIT_SUCCESS,
  CARD_LIMITS_HEADING,
  SAVE_CHANGES_LOADING,
  TRY_AGAIN_MESSAGE,
} from './constants';
import BankLimits from './components/BankLimits';

const LimitsWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > div {
      width: 49%;
    }

    @media (max-width: 1200px) {
      flex-direction: column;

      > div {
        padding-bottom: ${theme.spacings.peta};
        width: 100%;
      }
    }
  `
);

const StyledHeading = styled(Headline)`
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 40px;

  > div {
    display: flex;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  max-height: 40px;
  align-items: center;
`;

const ErrorWrapper = styled('div')(
  ({ theme }) => `
    margin-top: ${theme.spacings.mega};
  `
);

const InactiveBlock = styled(Inactive)`
  margin-top: 20px;
`;

const initialState = { loading: true };

const Limits = () => {
  const { clientId } = useParams();
  const httpClient = useHttpClient();
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [columns, setColumns] = useState(initialCardLimitsColumns);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelButtonEnabled, setCancelButtonEnabled] = useState(true);

  const initialLoad = useRef(true);

  const {
    state: { permissions },
  } = useContext(AuthContext);

  const {
    state: { externalLinks = {} },
  } = useContext(ClientDataContext);

  const { setToast } = useNotificationToast();

  const { paysolut: ebcUrl } = externalLinks;

  const [
    {
      cardLimits,
      bankLimits,
      loadCardLimitsError,
      loadBankLimitsError,
      directDebitsEnabled,
      canCreateBankAccount,
      country,
      currency,
      loading,
      primaryCard,
    },
    dispatch,
  ] = useReducer(limitsReducer, initialState);

  const primaryCardToken = primaryCard?.token;

  useEffect(() => {
    dispatch(setLimitsLoading(true));
    getClientDetailsWithLimits(httpClient, {
      clientId,
      cardId: primaryCardToken,
    })
      .then((data) => {
        dispatch(loadLimitsSuccess(data));
      })
      .catch((err) => dispatch(loadError(err)));
  }, [clientId, httpClient, primaryCardToken]);

  const handleSetCardLimits = () => {
    setSaveButtonEnabled(false);

    dispatch(setLimitsLoading(true));
    setCancelButtonEnabled(false);

    saveCardLimits({ cardId: primaryCardToken, limits: cardLimits, httpClient })
      .then(() => {
        const cardLimitsClone = cardLimits.map((record) => ({
          ...record,
          modified: false,
        }));

        dispatch(setCardLimits(cardLimitsClone));

        setToast({
          variant: 'confirm',
          headline: CARD_LIMITS_EDIT_SUCCESS,
        });

        setInEditMode(false);
        setColumns(initialCardLimitsColumns);
        setCancelButtonEnabled(true);
      })
      .catch(() => {
        setToast({
          variant: 'alert',
          headline: CARD_LIMITS_EDIT_ERROR,
          body: TRY_AGAIN_MESSAGE,
        });

        dispatch(setLimitsLoading(false));
        setSaveButtonEnabled(true);
        setCancelButtonEnabled(true);
      });
  };

  const handleCardLimitsChange = () => {
    setSaveButtonEnabled(true);
    map((limit) => {
      map((value) => {
        if (value === '') {
          setSaveButtonEnabled(false);
        }
      }, values(limit));
    }, cardLimits);
  };

  const handleEditCardLimitsButtonClick = () => {
    setSaveButtonEnabled(false);

    const callback = () => {
      setColumns(
        inEditMode ? initialCardLimitsColumns : editableCardLimitsColumns
      );
      setInEditMode(!inEditMode);
    };

    if (inEditMode) {
      setCancelLoading(true);

      getCardLimits({ clientId, httpClient })
        .then(({ data }) => {
          dispatch(setCardLimits(data?.limits || []));
          callback();
          setCancelLoading(false);
        })
        .catch(() => {
          callback();
          setCancelLoading(false);
        });
    } else {
      callback();
    }
  };

  const handleEditBankLimitsButtonClick = () => {
    window.open(ebcUrl);
  };

  const hasEditRights = hasActionAccess({
    permissions,
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardLimitsEdit,
  });

  const hasCardLimits = cardLimits?.length > 0;
  const hasBankLimits = Object.keys(bankLimits || {}).length > 0;
  const showBAnotAvailableMessage =
    !loading && !initialLoad.current && canCreateBankAccount === false;

  useEffect(() => {
    initialLoad.current = !(hasCardLimits && hasBankLimits);
  }, [hasCardLimits, hasBankLimits]);

  return (
    <>
      {loading && initialLoad.current ? (
        <Loading />
      ) : (
        <LimitsWrapper>
          <div>
            <StyledHeading noMargin as="h2" size="two">
              {CARD_LIMITS_HEADING({ cardId: primaryCardToken })}
              <div>
                {hasEditRights && hasCardLimits && (
                  <StyledButton
                    variant="secondary"
                    loadingLabel={CANCEL_CHANGES_LOADING}
                    isLoading={cancelLoading}
                    disabled={!cancelButtonEnabled || loading}
                    onClick={handleEditCardLimitsButtonClick}
                  >
                    {inEditMode ? 'Cancel' : 'Edit'}
                  </StyledButton>
                )}
                {hasEditRights && inEditMode && hasCardLimits && (
                  <StyledButton
                    variant="primary"
                    disabled={!saveButtonEnabled}
                    loadingLabel={SAVE_CHANGES_LOADING}
                    isLoading={loading}
                    css={spacing({ left: 'byte' })}
                    onClick={handleSetCardLimits}
                  >
                    Save
                  </StyledButton>
                )}
              </div>
            </StyledHeading>
            {loadCardLimitsError && (
              <ErrorWrapper>
                <Error>{loadCardLimitsError}</Error>
              </ErrorWrapper>
            )}
            {hasCardLimits && (
              <DataGrid
                columns={columns}
                dataSource={cardLimits}
                showModified
                showFailure
                errorsVisible
                disabled={!hasEditRights}
                country={country}
                onDisplayErrors={handleCardLimitsChange}
              />
            )}
          </div>
          <div>
            <StyledHeading noMargin as="h2" size="two">
              {BANK_LIMITS_HEADING}
              <div>
                {hasEditRights && hasBankLimits && (
                  <StyledButton
                    variant="secondary"
                    onClick={handleEditBankLimitsButtonClick}
                  >
                    <ExternalLink size="16" />
                    <Body
                      noMargin
                      variant="highlight"
                      size="one"
                      css={spacing({ left: 'byte' })}
                    >
                      Edit
                    </Body>
                  </StyledButton>
                )}
              </div>
            </StyledHeading>
            {showBAnotAvailableMessage ? (
              <InactiveBlock>
                Business account is not available in {COUNTRY_NAMES[country]}
                yet.
              </InactiveBlock>
            ) : (
              <>
                {loadBankLimitsError && (
                  <ErrorWrapper>
                    <Error>{loadBankLimitsError}</Error>
                  </ErrorWrapper>
                )}
                {hasBankLimits && (
                  <BankLimits
                    limits={bankLimits}
                    currency={currency?.code}
                    directDebitsEnabled={directDebitsEnabled}
                  />
                )}
              </>
            )}
          </div>
        </LimitsWrapper>
      )}
    </>
  );
};

export default Limits;
