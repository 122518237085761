import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ChevronRight } from '@sumup/icons';
import { MODULE_PICKER_WIDTH } from 'variables';

export const ModulePickerWrapper = styled('div')`
  position: relative;
`;

export const ModulePickerModules = styled('div')(
  ({ theme }) => css`
    display: flex;
    border-top: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    width: 100%;
    min-height: 48px;
    background: ${theme.colors.white};
    & > p {
      cursor: pointer;
      font-size: 0;
      ${theme.mq.giga} {
        font-size: initial;
      }
    }

    svg {
      color: ${theme.colors.n500};
    }
  `
);

export const ModulePickerModalWrapper = styled('div')(
  ({ theme: { borderRadius } }) => css`
    display: flex;
    flex-direction: row;
    width: ${MODULE_PICKER_WIDTH}px;
    padding: 0;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${borderRadius.bit};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 2;
    overflow: hidden;
  `
);

export const Modules = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 50%;
    border-right: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
    border-radius: 3px 0px 0px 3px;
    background: ${theme.colors.n100};
  `
);

export const ModuleBackdrop = styled('div')`
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.2;
`;

export const Module = styled('div')(
  ({ theme, hovered }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 64px;
    padding: 0px ${theme.spacings.kilo} 0px 20px;
    background: ${hovered ? theme.colors.white : theme.colors.n100};
    cursor: pointer;
    border-bottom: ${theme.borderWidth.kilo} solid ${theme.colors.n200};
  `
);

export const Sections = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: ${theme.spacings.kilo};
  `
);

export const ModuleName = styled('div')`
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ModuleArrow = styled(ChevronRight)`
  flex-shrink: 0;
  margin-left: auto;
`;

export const ArrowRight = styled(ChevronRight)(
  ({ theme }) => css`
    ${theme.mq.giga} {
      margin-left: ${theme.spacings.byte};
    }
  `
);
