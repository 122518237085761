import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';

export const BalanceWrapper = styled(Body)(
  ({ theme }) => css`
    text-align: center;
    padding-top: ${theme.spacings.mega};
  `
);
