import React, { useCallback, useEffect, useReducer } from 'react';
import { BaseStyles, ModalProvider, ToastProvider } from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import { ThemeProvider, Global } from '@emotion/react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { includes } from 'lodash';
import Private from 'routes/Private';
import Public from 'routes/Public';
import Admin from 'components/Admin';
import ActivityLog from 'components/ActivityLog';
import Transactions from 'components/Transactions';
import DevicesList from 'components/DevicesList';
import Challenges from 'components/Challenges';
import TopBar from 'components/TopBar';
import Limits from 'components/Limits';
import Activation from 'components/Activation';
import Account from 'components/Account';
import Login from 'components/Login';
import UserAdministrationUsers from 'components/UserAdministrationUsers';
import UserAdministrationUserGroups from 'components/UserAdministrationUserGroups';
import FraudReport from 'components/FraudReport';
import MassActions from 'components/MassActions';
import MatchClearings from 'components/MatchClearings';
import AdjustAuthorizations from 'components/AdjustAuthorizations';
import CancelAuthorizations from 'components/CancelAuthorizations';
import { SECTIONS as ADMINISTRATION_SECTIONS } from 'components/UserAdministration/constants';
import { SECTIONS as REPORTS_SECTIONS } from 'components/FraudReport/constants';
import { SECTIONS as MASS_ACTIONS_SECTIONS } from 'components/MassActions/constants';
import { SECTIONS as CARD_RECONCILIATION_SECTIONS } from 'components/Reconciliation/constants';
import { NBA_BUNDLING_SECTIONS } from 'components/NbaBundling/constants';
import DirectDebits from 'components/DirectDebits';
import ScheduledPayments from 'components/ScheduledPayments';
import { BatchEnrolment } from 'components/NbaBundling/BatchEnrolment';
import {
  PATHS,
  LOGGED_USER_COOKIE,
  APPS,
  PAGE_TITLES,
  SECTIONS,
  REDIRECT_URI,
} from 'variables';
import AuthContext from 'context/auth';
import { getLoggedUserPermissions, logOut } from 'api';
import { useHttpClient } from 'hooks';
import { hasMerchantAccess, hasSectionAccess } from 'services/permissions';
import MerchantPage from 'components/MerchantPage';
import OpsTransfers from 'components/OpsTransfers';
import BankFraudReport from './components/BankFraudReport';
import { authReducer } from './authReducer';
import { setLoading, auth, setPermissions } from './authActions';
import { initialState } from './state';

const {
  merchant,
  administration,
  reports,
  card_reconciliation: reconciliation,
  mass_actions: massActions,
  ops_transfers: opsTransfers,
} = APPS;

const {
  accountcardActivation,
  accountcardActivityLog,
  accountcardLimits,
  accountcardOverview,
  accountcardPsdChallenges,
  accountcardScaDevices,
  accountcardTransactions,
  accountcardDirectDebits,
  accountcardScheduledPayments,
} = SECTIONS[merchant];

const { groups, users } = SECTIONS[administration];
const { mcReport } = SECTIONS[reports];
const { clearings, authorizations, cancelauthorizations } =
  SECTIONS[reconciliation];
const { actions } = SECTIONS[massActions];
const { requests } = SECTIONS[opsTransfers];

export default function App() {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { loading, isAuthenticated, user: userId, permissions } = state;
  const httpClient = useHttpClient();

  const handleLogout = useCallback(() => {
    dispatch(auth(null));
    dispatch(setLoading(false));
    logOut(httpClient);
  }, [httpClient]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const { pathname } = url;

    const user = url.searchParams.get(LOGGED_USER_COOKIE);

    if (user) {
      Cookies.set(LOGGED_USER_COOKIE, user, {
        expires: 1 / 24,
      });

      const redirectUri = Cookies.get(REDIRECT_URI);
      if (redirectUri && pathname !== redirectUri) {
        window.open(redirectUri, '_self');
        Cookies.remove(REDIRECT_URI);
      } else if (window.history.pushState) {
        const newURL = new URL(window.location.href);
        newURL.search = '';
        window.history.pushState({ path: newURL.href }, '', newURL.href);
      }
    }

    if (Cookies.get(LOGGED_USER_COOKIE)) {
      dispatch(auth(Cookies.get(LOGGED_USER_COOKIE)));

      setLoading(true);
      getLoggedUserPermissions({ httpClient }).then(({ data }) => {
        dispatch(setPermissions(data));
      });
    } else {
      handleLogout();
    }

    if (
      pathname &&
      !includes(pathname, PATHS.login) &&
      !Cookies.get(LOGGED_USER_COOKIE)
    ) {
      Cookies.set(REDIRECT_URI, pathname);
    }
  }, [httpClient, handleLogout]);

  const getMerchantSectionAccess = ({ section }) =>
    hasSectionAccess({
      permissions,
      app: merchant,
      section,
    });

  const getAdministrationSectionAccess = ({ section }) =>
    hasSectionAccess({
      permissions,
      app: administration,
      section,
    });

  const getReportsSectionAccess = ({ section }) =>
    hasSectionAccess({
      permissions,
      app: reports,
      section,
    });

  const getCardReconciliationAccess = ({ section }) =>
    hasSectionAccess({
      permissions,
      app: reconciliation,
      section,
    });

  const getMassActionsSectionAccess = ({ section }) =>
    hasSectionAccess({
      permissions,
      app: massActions,
      section,
    });

  const getOpsTransfersSectionAccess = ({ section }) =>
    hasSectionAccess({
      permissions,
      app: opsTransfers,
      section,
    });

  const user = atob(userId);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <ThemeProvider theme={light}>
        <BaseStyles />
        <Global />
        <ToastProvider>
          <Router>
            <ModalProvider>
              {isAuthenticated && (
                <TopBar onLogout={handleLogout} user={user} />
              )}
              {!loading && (
                <Switch>
                  <Public
                    restricted
                    component={Login}
                    path={`/${PATHS.login}`}
                    render={PATHS.login}
                    title={PAGE_TITLES.LOGIN.title}
                  />
                  {getMerchantSectionAccess({
                    section: accountcardOverview,
                  }) && (
                    <Private
                      component={Account}
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.account}/:accountId?`}
                      exact
                      title={PAGE_TITLES.ACCOUNT.title}
                      render={PATHS.account}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardActivation,
                  }) && (
                    <Private
                      component={Activation}
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.activation}`}
                      title={PAGE_TITLES.ACTIVATION.title}
                      render={PATHS.activation}
                    />
                  )}
                  {hasMerchantAccess(permissions) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId`}
                      title={PAGE_TITLES.CLIENT.title}
                      render={PATHS.client}
                      exact
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardLimits,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.limits}`}
                      component={Limits}
                      title={PAGE_TITLES.LIMITS.title}
                      render={PATHS.limits}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardPsdChallenges,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.challenges}`}
                      component={Challenges}
                      title={PAGE_TITLES.CHALLENGES.title}
                      render={PATHS.challenges}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardScaDevices,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.devices}`}
                      component={DevicesList}
                      title={PAGE_TITLES.DEVICES.title}
                      render={PATHS.devices}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardTransactions,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.transactions}/:txId?`}
                      component={Transactions}
                      title={PAGE_TITLES.TRANSACTIONS.title}
                      render={PATHS.transactions}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardTransactions,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.account}/:accountId/${PATHS.transactions}/:txId?`}
                      component={Transactions}
                      title={PAGE_TITLES.TRANSACTIONS.title}
                      render={PATHS.transactions}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardActivityLog,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.activity}`}
                      component={ActivityLog}
                      title={PAGE_TITLES.ACTIVITY_LOG.title}
                      render={PATHS.activity}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardDirectDebits,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.direct_debits}`}
                      component={DirectDebits}
                      title={PAGE_TITLES.DIRECT_DEBITS.title}
                      render={PATHS.direct_debits}
                    />
                  )}
                  {getMerchantSectionAccess({
                    section: accountcardScheduledPayments,
                  }) && (
                    <Private
                      page={MerchantPage}
                      path={`/${PATHS.client}/:clientId/${PATHS.scheduled_payments}`}
                      component={ScheduledPayments}
                      title={PAGE_TITLES.SCHEDULED_PAYMENTS.title}
                      render={PATHS.scheduled_payments}
                    />
                  )}
                  {getAdministrationSectionAccess({
                    section: users,
                  }) && (
                    <Private
                      path={`/${PATHS.user_administration}/${PATHS.users_with_groups}`}
                      component={UserAdministrationUsers}
                      title={PAGE_TITLES.USER_ADMINISTRATION.title}
                      render={PATHS.users_with_groups}
                      heading={PAGE_TITLES.USER_ADMINISTRATION.heading}
                      link={PATHS.user_administration}
                      sections={ADMINISTRATION_SECTIONS}
                    />
                  )}
                  {getAdministrationSectionAccess({
                    section: groups,
                  }) && (
                    <Private
                      path={`/${PATHS.user_administration}/${PATHS.user_groups}`}
                      component={UserAdministrationUserGroups}
                      title={PAGE_TITLES.USER_ADMINISTRATION.title}
                      render={PATHS.user_groups}
                      heading={PAGE_TITLES.USER_ADMINISTRATION.heading}
                      link={PATHS.user_administration}
                      sections={ADMINISTRATION_SECTIONS}
                    />
                  )}
                  {getReportsSectionAccess({
                    section: mcReport,
                  }) && (
                    <Private
                      path={`/${PATHS.mc_report}/${PATHS.fraud}`}
                      component={FraudReport}
                      title={PAGE_TITLES.REPORTS.title}
                      render={PATHS.fraud}
                      heading={PAGE_TITLES.REPORTS.heading}
                      link={PATHS.mc_report}
                      sections={REPORTS_SECTIONS}
                    />
                  )}
                  {getReportsSectionAccess({
                    section: mcReport,
                  }) && (
                    <Private
                      path={`/${PATHS.mc_report}/${PATHS.bank_fraud}`}
                      component={BankFraudReport}
                      title={PAGE_TITLES.REPORTS.title}
                      render={PATHS.bank_fraud}
                      heading={PAGE_TITLES.REPORTS.heading}
                      link={PATHS.mc_report}
                      sections={REPORTS_SECTIONS}
                    />
                  )}
                  {getMassActionsSectionAccess({
                    section: actions,
                  }) && (
                    <Private
                      path={`/${PATHS.mass_actions}/${PATHS.actions}`}
                      component={MassActions}
                      title={PAGE_TITLES.MASS_ACTIONS.title}
                      render={PATHS.actions}
                      heading={PAGE_TITLES.MASS_ACTIONS.heading}
                      link={PATHS.mass_actions}
                      sections={MASS_ACTIONS_SECTIONS}
                    />
                  )}
                  {getCardReconciliationAccess({
                    section: clearings,
                  }) && (
                    <Private
                      path={`/${PATHS.reconciliation}/${PATHS.match_clearings}`}
                      component={MatchClearings}
                      title={PAGE_TITLES.RECONCILIATION.title}
                      render={PATHS.match_clearings}
                      heading={PAGE_TITLES.RECONCILIATION.heading}
                      link={PATHS.reconciliation}
                      sections={CARD_RECONCILIATION_SECTIONS}
                    />
                  )}
                  {getCardReconciliationAccess({
                    section: authorizations,
                  }) && (
                    <Private
                      path={`/${PATHS.reconciliation}/${PATHS.adjust_authorizations}`}
                      component={AdjustAuthorizations}
                      title={PAGE_TITLES.RECONCILIATION.title}
                      render={PATHS.adjust_authorizations}
                      heading={PAGE_TITLES.RECONCILIATION.heading}
                      link={PATHS.reconciliation}
                      sections={CARD_RECONCILIATION_SECTIONS}
                    />
                  )}
                  {getOpsTransfersSectionAccess({
                    section: requests,
                  }) && (
                    <Private
                      path={`/${PATHS.ops_transfers}/${PATHS.requests}`}
                      component={OpsTransfers}
                      title={PAGE_TITLES.OPS_TRANSFERS.title}
                      render={PATHS.requests}
                      heading={PAGE_TITLES.OPS_TRANSFERS.heading}
                      link={PATHS.ops_transfers}
                      user={user}
                    />
                  )}
                  {getCardReconciliationAccess({
                    section: cancelauthorizations,
                  }) && (
                    <Private
                      path={`/${PATHS.reconciliation}/${PATHS.cancel_authorizations}`}
                      component={CancelAuthorizations}
                      title={PAGE_TITLES.RECONCILIATION.title}
                      render={PATHS.cancel_authorizations}
                      heading={PAGE_TITLES.RECONCILIATION.heading}
                      link={PATHS.reconciliation}
                      sections={CARD_RECONCILIATION_SECTIONS}
                    />
                  )}
                  <Private
                    path={`/${PATHS.bundling}/${PATHS.batch}`}
                    component={BatchEnrolment}
                    title={PAGE_TITLES.NBA_BUNDLING.title}
                    heading={PAGE_TITLES.NBA_BUNDLING.heading}
                    render={PATHS.batch}
                    link={PATHS.bundling}
                    sections={NBA_BUNDLING_SECTIONS}
                  />
                  <Private
                    path="/"
                    component={Admin}
                    title={PAGE_TITLES.ADMIN.title}
                    render="/"
                  />
                </Switch>
              )}
            </ModalProvider>
          </Router>
        </ToastProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}
