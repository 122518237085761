import React, { useCallback, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  cancelTransaction,
  chargebackTransaction,
  getClientTransactions,
  markAsPotentialFraud,
  buildTransactionsExportUrl,
  markBankTransactionFraud,
  editBankTransactionFraud,
  unmarkBankTransactionFraud,
} from 'api';
import { useApiCallOnLoad, useHttpClient } from 'hooks';
import Error from 'components/Error';
import {
  formatDatePeriodStartDate,
  formatDatePeriodEndDate,
  setDatePeriodCookie,
} from 'services/transactions';
import {
  APPS,
  DATE_FORMATS,
  PATHS,
  TRANSACTIONS_DATE_PERIOD_COOKIE,
  TRANSACTIONS_PAGE_SIZE_COOKIE,
  AUTH_CODE_QUERY_PARAM,
  RRN_QUERY_PARAM,
} from 'variables';
import AuthContext from 'context/auth';
import { hasActionAccess, hasSectionAccess } from 'services/permissions';
import { loadSuccess, setLoading, loadError } from 'actions';
import { reduce, compact } from 'lodash';
import { columns } from './fieldsConfig';
import { Transactions } from './Transactions';
import { transactionsReducer } from './transactionsReducer';
import {
  setFilterDateRange,
  setPage,
  setTransactionOriginFilter,
  setTransactionDirectionFilter,
  setFilter,
  applyFilters,
  setPageSize,
  clearFilters,
  sortTransactions,
} from './transactionsActions';
import { initialState } from './state';
import {
  ALL_TRANSACTION_DIRECTIONS,
  ALL_TRANSACTION_ORIGINS,
  DATE_PERIOD_OPTIONS,
  SORT_DIRECTION_MAP,
  TRANSACTION_AUTH_CODE_FILTER,
  TRANSACTION_FILTERS_ANY_OPTION,
  TRANSACTION_ID_FILTER,
  TRANSACTION_RRN_FILTER,
} from './constants';

const TransactionsData = () => {
  const { clientId, accountId } = useParams();
  const { txId } = useParams();
  const {
    state: { permissions },
  } = useContext(AuthContext);
  const httpClient = useHttpClient();
  const scrollPosition = useRef('');
  const history = useHistory();

  const setScrollPosition = (value) => {
    scrollPosition.current = value;
  };

  const url = new URL(window.location.href);
  const authCode = url.searchParams.get(AUTH_CODE_QUERY_PARAM) || '';
  const rrn = url.searchParams.get(RRN_QUERY_PARAM) || '';

  const datePeriodCookie = Cookies.get(TRANSACTIONS_DATE_PERIOD_COOKIE);
  const pageSizeCookie = Cookies.get(TRANSACTIONS_PAGE_SIZE_COOKIE);

  let initialDatePeriod = { ...initialState.datePeriod };
  let initialPageSize = initialState.pageSize;

  if (datePeriodCookie) {
    const [name, startDate, endDate] = datePeriodCookie.split('_');

    initialDatePeriod = {
      name,
      startDate: moment(startDate, DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
      endDate: moment(endDate, DATE_FORMATS.YEAR_SHORT_MONTH_DAY_TIME),
      ...DATE_PERIOD_OPTIONS[name],
    };
  } else {
    const { datePeriod } = initialState;
    setDatePeriodCookie(datePeriod, TRANSACTIONS_DATE_PERIOD_COOKIE);
  }

  if (pageSizeCookie) {
    initialPageSize = pageSizeCookie;
  } else {
    const { pageSize } = initialState;
    Cookies.set(TRANSACTIONS_PAGE_SIZE_COOKIE, pageSize);
  }

  const [state, dispatch] = useApiCallOnLoad(
    useCallback(
      () =>
        getClientTransactions({
          httpClient,
          clientId,
          page: initialState.currentPage,
          pageSize: Number(initialPageSize),
          startDate: formatDatePeriodStartDate(initialDatePeriod.startDate),
          endDate: formatDatePeriodEndDate(initialDatePeriod.endDate),
          identifier: txId,
          authCode: authCode || '',
          rrn: rrn || '',
          accountId: accountId || '',
        }),
      [accountId]
    ),
    clientId,
    transactionsReducer,
    {
      ...initialState,
      datePeriod: {
        ...initialState.datePeriod,
        ...initialDatePeriod,
      },
      pageSize: Number(initialPageSize),
      filters: txId
        ? {
            ...initialState.filters,
            [TRANSACTION_ID_FILTER]: txId,
            [TRANSACTION_AUTH_CODE_FILTER]: authCode,
            [TRANSACTION_RRN_FILTER]: rrn,
          }
        : {
            ...initialState.filters,
            [TRANSACTION_AUTH_CODE_FILTER]: authCode,
            [TRANSACTION_RRN_FILTER]: rrn,
          },
      filtersApplied:
        initialState.filtersApplied || !!txId || !!authCode || !!rrn,
    }
  );

  const {
    loading,
    error,
    transactions,
    pageSize,
    currentPage,
    totalPages,
    totalCount,
    datePeriod = {},
    transactionOriginFilter,
    transactionDirectionFilter,
    filters,
    filtersApplied,
    transactionStatuses,
    transactionTypes,
    sort = {},
    applyFiltersEnabled,
  } = state;

  const formattedStartDate = formatDatePeriodStartDate(datePeriod.startDate);
  const formattedEndDate = formatDatePeriodEndDate(datePeriod.endDate);

  const selectedFilters = reduce(
    filters,
    (accumulator, value, filter) => ({
      ...accumulator,
      ...(value.name !== TRANSACTION_FILTERS_ANY_OPTION.name && {
        [filter]: value.name || value,
      }),
    }),
    {}
  );

  const resolvePath = (id = '') => {
    const path = [
      `/${PATHS.client}`,
      clientId,
      accountId && PATHS.account,
      accountId,
      PATHS.transactions,
      id,
    ];

    return compact(path).join('/');
  };

  const { fieldName: sortBy } = sort;
  const sortDirection = SORT_DIRECTION_MAP[sort[sortBy] || ''];
  const originFilter =
    transactionOriginFilter === ALL_TRANSACTION_ORIGINS
      ? undefined
      : transactionOriginFilter;
  const directionFilter =
    transactionDirectionFilter === ALL_TRANSACTION_DIRECTIONS
      ? undefined
      : transactionDirectionFilter;

  // useEffect(() => {
  //   if (txId && httpClient) {
  //     getClientTransactions({
  //       httpClient,
  //       clientId,
  //       identifier: txId,
  //     })
  //       .then((data) => {
  //         dispatch(loadSuccess(data));
  //       })
  //       .catch(() => dispatch(loadError()));
  //   }
  // }, [txId, httpClient, clientId, dispatch]);

  const updateTransactions = (args, successHandler) => {
    dispatch(setLoading(true));

    return getClientTransactions({
      httpClient,
      clientId,
      ...args,
      accountId,
    })
      .then((data) => {
        dispatch(loadSuccess(data));

        if (successHandler) {
          successHandler();
        }
      })
      .catch(() => dispatch(loadError()));
  };

  const handleDateRangeChange = (dateRange) => {
    dispatch(setFilterDateRange(dateRange));

    const { name, startDate, endDate } = dateRange;

    if (startDate && endDate) {
      setDatePeriodCookie(
        { name, startDate, endDate },
        TRANSACTIONS_DATE_PERIOD_COOKIE
      );

      const { currentPage: page } = initialState;

      updateTransactions(
        {
          pageSize,
          page,
          origin: originFilter,
          direction: directionFilter,
          startDate: formatDatePeriodStartDate(startDate),
          endDate: formatDatePeriodEndDate(endDate),
          ...selectedFilters,
          sortBy,
          sortDirection,
        },
        () => dispatch(setPage({ page }))
      );
    }
  };

  const handlePageChange = (page) => {
    updateTransactions(
      {
        pageSize,
        page,
        origin: originFilter,
        direction: directionFilter,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        ...selectedFilters,
        sortBy,
        sortDirection,
      },
      () => dispatch(setPage({ page }))
    );
  };

  const handleTransactionOriginFilterChange = (origin) => {
    const { currentPage: page } = initialState;

    updateTransactions(
      {
        pageSize,
        page,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        origin,
        direction: directionFilter,
        ...selectedFilters,
        sortBy,
        sortDirection,
      },
      () => dispatch(setTransactionOriginFilter({ origin }))
    );
  };

  const handleTransactionDirectionFilterChange = (direction) => {
    const { currentPage: page } = initialState;

    updateTransactions(
      {
        pageSize,
        page,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        origin: originFilter,
        direction,
        ...selectedFilters,
        sortBy,
        sortDirection,
      },
      () => dispatch(setTransactionDirectionFilter({ direction }))
    );
  };

  const handleFilterChange = ({ field, value }) => {
    dispatch(setFilter({ field, value }));
  };

  const handleFiltersApply = () => {
    const { currentPage: page } = initialState;

    updateTransactions(
      {
        pageSize,
        page,
        origin: originFilter,
        direction: directionFilter,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        ...selectedFilters,
        sortBy,
        sortDirection,
      },
      () => {
        dispatch(applyFilters());

        if (!authCode && !rrn) {
          const idFilter = selectedFilters[TRANSACTION_ID_FILTER];
          history.push(resolvePath(idFilter));
        }
      }
    );
  };

  const handleFiltersClear = () => {
    const { currentPage: page } = initialState;

    updateTransactions(
      {
        pageSize,
        page,
        origin: originFilter,
        direction: directionFilter,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        sortBy,
        sortDirection,
      },
      () => {
        dispatch(clearFilters());

        history.push(resolvePath());
      }
    );
  };

  const handlePageSizeChange = (size) => {
    const { currentPage: page } = initialState;

    updateTransactions(
      {
        pageSize: size,
        page,
        origin: originFilter,
        direction: directionFilter,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        ...selectedFilters,
        sortBy,
        sortDirection,
      },
      () => {
        dispatch(setPageSize({ size }));

        Cookies.set(TRANSACTIONS_PAGE_SIZE_COOKIE, size);
      }
    );
  };

  const handleSort = ({ name, order }) => {
    updateTransactions(
      {
        pageSize,
        page: currentPage,
        origin: originFilter,
        direction: directionFilter,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        ...selectedFilters,
        sortBy: name,
        sortDirection: SORT_DIRECTION_MAP[order],
      },
      () => dispatch(sortTransactions({ name, order }))
    );
  };

  const hasActionAccessWrapped = ({ app = APPS.merchant, action }) =>
    hasActionAccess({ permissions, app, action });

  const hasSectionAccessWrapped = ({ app = APPS.merchant, section }) =>
    hasSectionAccess({ permissions, app, section });

  const handleMarkAsPotentialFraud = ({
    txId: transactionId,
    fraudTypeCode,
    fraudSubtypeCode,
  }) => {
    setScrollPosition(window.pageYOffset);

    return markAsPotentialFraud({
      httpClient,
      txId: transactionId,
      fraudTypeCode,
      fraudSubtypeCode,
    })
      .then(() =>
        updateTransactions({
          pageSize,
          page: currentPage,
          origin: originFilter,
          direction: directionFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          ...selectedFilters,
          sortBy,
          sortDirection,
        })
      )
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));
  };

  const handleCancelTransaction = ({ txId: transactionId }) =>
    cancelTransaction({
      clientId,
      txId: transactionId,
      httpClient,
    })
      .then(() => {
        setScrollPosition(window.pageYOffset);

        return updateTransactions({
          pageSize,
          page: currentPage,
          origin: originFilter,
          direction: directionFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          ...selectedFilters,
          sortBy,
          sortDirection,
        });
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));

  const handleChargebackTransaction = ({ txId: transactionId }) =>
    chargebackTransaction({
      clientId,
      txId: transactionId,
      httpClient,
    })
      .then(() => {
        setScrollPosition(window.pageYOffset);

        return updateTransactions({
          pageSize,
          page: currentPage,
          origin: originFilter,
          direction: directionFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          ...selectedFilters,
          sortBy,
          sortDirection,
        });
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));

  const handleMarkBankTransactionFraud = ({
    paymentId,
    type,
    subtype,
    date,
    status,
    recoveredAmount,
    reference,
    details,
  }) => {
    markBankTransactionFraud({
      httpClient,
      paymentId,
      type,
      subtype,
      date,
      status,
      recoveredAmount,
      reference,
      details,
    })
      .then(() => {
        setScrollPosition(window.pageYOffset);

        return updateTransactions({
          pageSize,
          page: currentPage,
          origin: originFilter,
          direction: directionFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          ...selectedFilters,
          sortBy,
          sortDirection,
        });
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));
  };

  const handleEditBankTransactionFraud = ({
    paymentId,
    type,
    subtype,
    date,
    status,
    recoveredAmount,
    reference,
    details,
    lastModified,
    reportId,
  }) => {
    editBankTransactionFraud({
      httpClient,
      paymentId,
      type,
      subtype,
      date,
      status,
      recoveredAmount,
      reference,
      details,
      lastModified,
      reportId,
    })
      .then(() => {
        setScrollPosition(window.pageYOffset);

        return updateTransactions({
          pageSize,
          page: currentPage,
          origin: originFilter,
          direction: directionFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          ...selectedFilters,
          sortBy,
          sortDirection,
        });
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));
  };

  const handleUnmarkBankTransactionFraud = ({
    paymentId,
    reportId,
    comment,
    lastModified,
  }) => {
    unmarkBankTransactionFraud({
      httpClient,
      paymentId,
      reportId,
      comment,
      lastModified,
    })
      .then(() => {
        setScrollPosition(window.pageYOffset);

        return updateTransactions({
          pageSize,
          page: currentPage,
          origin: originFilter,
          direction: directionFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          ...selectedFilters,
          sortBy,
          sortDirection,
        });
      })
      .then(() => {
        window.scroll({ top: scrollPosition.current, behavior: 'smooth' });
      })
      .catch(() => dispatch(loadError()));
  };

  const handleExport = (extension) => {
    window.location.href = buildTransactionsExportUrl({
      clientId,
      pageSize,
      page: currentPage,
      origin: originFilter,
      direction: directionFilter,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...selectedFilters,
      sortBy,
      sortDirection,
      extension,
      accountId,
    });
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      <Transactions
        loading={loading}
        columns={columns}
        transactions={transactions}
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={totalPages}
        totalCount={totalCount}
        datePeriod={datePeriod}
        filters={filters}
        filtersApplied={filtersApplied}
        txOriginFilter={transactionOriginFilter}
        txDirectionFilter={transactionDirectionFilter}
        transactionStatuses={transactionStatuses}
        transactionTypes={transactionTypes}
        sort={sort}
        clientId={clientId}
        applyFiltersEnabled={applyFiltersEnabled}
        onDateRangeChange={handleDateRangeChange}
        onPageChange={handlePageChange}
        onTransactionOriginFilterChange={handleTransactionOriginFilterChange}
        onTransactionDirectionFilterChange={
          handleTransactionDirectionFilterChange
        }
        onFilterChange={handleFilterChange}
        onFiltersApply={handleFiltersApply}
        onFiltersClear={handleFiltersClear}
        onPageSizeChange={handlePageSizeChange}
        onSort={handleSort}
        dispatch={dispatch}
        hasActionAccess={hasActionAccessWrapped}
        hasSectionAccess={hasSectionAccessWrapped}
        onMarkAsPotentialFraud={handleMarkAsPotentialFraud}
        onCancelTransaction={handleCancelTransaction}
        onChargebackTransaction={handleChargebackTransaction}
        onMarkBankTransactionFraud={handleMarkBankTransactionFraud}
        onEditBankTransactionFraud={handleEditBankTransactionFraud}
        onUnmarkBankTransactionFraud={handleUnmarkBankTransactionFraud}
        onExport={handleExport}
      />
    </>
  );
};

export default TransactionsData;
