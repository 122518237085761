import moment from 'moment';
import keyMirror from 'key-mirror-nested';

import {
  DATE_PERIOD_YESTERDAY,
  DATE_PERIOD_TWO_DAYS,
  DATE_PERIOD_FIVE_DAYS,
  DATE_PERIOD_CUSTOM,
} from 'variables';

export const AUTHORIZATIONS_FOR_ADJUSTMENT = 'Authorizations for adjustment';
export const AUTHORIZATIONS_GRID_FIRST_PAGE = 1;

export const DATE_PERIOD_OPTIONS = {
  [DATE_PERIOD_YESTERDAY]: {
    name: DATE_PERIOD_YESTERDAY,
    label: 'Yesterday',
    days: null,
    startDate: moment().subtract(1, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_TWO_DAYS]: {
    name: DATE_PERIOD_TWO_DAYS,
    label: 'Last 2 days',
    days: 2,
    startDate: moment().subtract(2, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_FIVE_DAYS]: {
    name: DATE_PERIOD_FIVE_DAYS,
    label: 'Last 5 days',
    days: 5,
    startDate: moment().subtract(5, 'd'),
    endDate: moment(),
  },
  [DATE_PERIOD_CUSTOM]: {
    name: DATE_PERIOD_CUSTOM,
    label: 'Custom period',
    days: null,
  },
};

export const BUTTONS_ACTIONS = keyMirror({
  IGNORE: null,
  RETURN: null,
  CHARGE: null,
});

export const CARD_TOKEN_FILTER = 'cardToken';
export const BILLING_CURRENCY_FILTER = 'billingCurrency';

export const BILLING_CURRENCY_FILTERS_ANY_OPTION = {
  name: 'any',
  label: 'Any',
};

export const CURRENCIES = [
  'EUR',
  'GBP',
  'PLN',
  'SEK',
  'NOK',
  'CZK',
  'RON',
  'DKK',
  'HUF',
];

export const PRE_AUTH_FILTER = 'transactionStatus';
export const PRE_AUTH_FILTER_ANY_OPTION = {
  name: 'all',
  label: 'All',
};

export const PRE_AUTH_FILTER_MAP = [
  { name: '4', label: 'Yes' },
  { name: '0', label: 'No' },
];

export const TRANSACTION_FILTERS_ANY_OPTION = { name: 'any', label: 'Any' };

export const HIGHLIGHT_MERCHANTS = [
  '11teamsports satteldorf',
  'adidas',
  'air france roissy cdg ce fr',
  'alcott napoli it',
  'asics.com',
  'atlasformen paris fr',
  'auchan.fr',
  'coach uk',
  'diesel',
  'diffusione tessile',
  'foot locker',
  'fnac.com ivry sur sein fr',
  'freedom sportsline ltdlondon gb',
  'greenweez sa saint jorioz fr',
  'hollister amsterdam nl',
  'home24 se berlin de',
  'hpy*makro autoservi',
  'hpy*veepee',
  'idkids roubaix fr',
  'intersport france longjumeau fr',
  'j.m. bruneau villebon sur fr',
  'kiabi hem',
  'klarna*',
  'lego system a/s billund dk',
  'leroy',
  'levis online shop diegem be',
  'lidl',
  'lll athletica uk ltd london gb',
  'maisonsdumonde',
  'makro.es',
  'matalan ecom liverpool gb',
  'max mara srl',
  'mgp*pfs pfs sas paris fr',
  'media markt ingolstadt de',
  'media markt',
  'metro.',
  'miu miu lisboa pt',
  'miroglio fashion',
  'nisbets ltd bristol gb',
  'office london',
  'ovs',
  'orchestra saint aunes',
  'paypal *ebay',
  'paypal *fnac direct',
  'paypal *foot',
  'paypal *ovs',
  'paypal *spod',
  'paypal *thalia.de',
  'paypal *yoox',
  'prada',
  'target.com',
  'rp*faces consent stafford gb',
  's2p*yoox milano it',
  'salmoiraghi&vigano milan it',
  'shop apotheke venlo nl',
  'supermercato todis roma it',
  'www.mediashop.tv neunkirchen at',
];
