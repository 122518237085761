import React, { useRef, useState } from 'react';
import {
  Body,
  Input,
  RadioButtonGroup,
  SubHeadline,
  spacing,
} from '@sumup/circuit-ui';
import { debounce } from 'lodash/fp';
import { map, some, values, trim, reduce } from 'lodash';
import PropTypes from 'prop-types';
import {
  CARD_TYPES,
  COUNTRY_NAMES,
  CURRENT_ADDRESS,
  CUSTOM_ADDRESS,
  DEBOUNCE_DELAY,
  MODALS,
  REPLACE_REASONS,
} from 'variables';
import Modal from 'components/Modal';
import {
  CustomAddress,
  StyledRadioButtonGroup,
  IssueCardForm,
  CountryIcon,
  StyledSelectorGroup,
  CardReplaceContent,
} from '../../../../AccountStyled';
import {
  CARDHOLDER_FIELDS,
  CUSTOM_ADDRESS_FIELDS,
  validateField,
} from './service';

const { cardholder_name: cardholderNameField } = CARDHOLDER_FIELDS;

const selectorGroupOptions = [
  {
    children: 'Physical',
    value: CARD_TYPES.physical,
  },
  {
    children: 'Virtual',
    value: CARD_TYPES.virtual,
  },
];

const replaceReasons = [
  {
    label: (
      <>
        <Body noMargin>The merchant needs a new card</Body>
        <Body noMargin variant="subtle">
          Old card can be used until new one arrives and is activated
        </Body>
      </>
    ),
    value: REPLACE_REASONS.OTHER,
    readOnly: true,
  },
  {
    label: (
      <>
        <Body noMargin>The card was stolen and/or used fraudulently</Body>
        <Body noMargin variant="subtle">
          Old card will be cancelled right away
        </Body>
      </>
    ),
    value: REPLACE_REASONS.STOLEN,
    readOnly: true,
  },
];

const IssueCardModal = ({
  address,
  personalProfile: { first_name: firstName, last_name: lastName },
  onIssueCard,
  onReplaceCard,
  onClose,
  cardReplace,
  expiringSoon,
}) => {
  const {
    address_line_1: addressLine1,
    city,
    post_code: postCode,
    country,
  } = address;

  const shipmentAddresses = [
    {
      label: `${addressLine1}, ${city}, ${postCode}, ${country}`,
      value: CURRENT_ADDRESS,
      readOnly: true,
    },
    { label: 'Custom address', value: CUSTOM_ADDRESS, readOnly: true },
  ];

  const [shipmentAddress, setShipmentAddress] = useState(
    shipmentAddresses[0].value
  );

  const [activeReplaceReason, setActiveReplaceReason] = useState(
    REPLACE_REASONS.OTHER
  );

  const [formFields, setFormFields] = useState({
    ...CUSTOM_ADDRESS_FIELDS,
    ...CARDHOLDER_FIELDS,
  });

  const [disabled, setDisabled] = useState(false);
  const [cardIssuing, setCardIssuing] = useState(false);
  const [cardTypeSelected, setCardTypeSelected] = useState(CARD_TYPES.physical);

  const customAddressOpen = shipmentAddress === CUSTOM_ADDRESS;
  const virtualCardSelected = cardTypeSelected === CARD_TYPES.virtual;
  const customAddress = useRef({});

  const clientFullName =
    firstName || lastName ? trim(`${firstName} ${lastName}`) : '';
  const cardholderName = useRef(clientFullName);

  const href = `/flags.svg#icon-flag-${country.toLowerCase()}`;

  const debouncedChange = debounce(DEBOUNCE_DELAY, (fields) =>
    setFormFields(fields)
  );

  const hasInvalidFields = (fields) =>
    some(
      values(fields),
      ({ invalid, validation = {}, value: fieldValue }) =>
        invalid === true || (validation.required && !fieldValue)
    );

  const handleCustomAddressChange = ({ target: { value, name } }) => {
    const fields = {
      ...formFields,
      [name]: {
        ...formFields[name],
        value,
        ...(formFields[name].validation &&
          validateField({ ...formFields[name].validation, value }, country)),
      },
    };

    debouncedChange(fields);

    const customAddressFormFields = { ...fields };
    delete customAddressFormFields.cardholder_name;

    customAddress.current = formFields;

    setDisabled(
      (shipmentAddress === CUSTOM_ADDRESS &&
        hasInvalidFields(customAddressFormFields)) ||
        !cardholderName.current
    );
  };

  const handleCardholderNameChange = ({ target: { value, name } }) => {
    setDisabled(
      validateField({ ...formFields[name].validation, value }).invalid
    );
  };

  const handleCardholderNameBlur = ({ target: { value, name } }) => {
    const fields = {
      ...formFields,
      [name]: {
        ...formFields[name],
        value,
        ...(formFields[name].validation &&
          validateField({ ...formFields[name].validation, value })),
      },
    };

    debouncedChange(fields);

    cardholderName.current = value;

    setDisabled(
      !value ||
        (shipmentAddress === CUSTOM_ADDRESS && hasInvalidFields(fields)) ||
        fields.cardholder_name.invalid
    );
  };

  const handleFocus = ({ target: { name } }) =>
    setFormFields({
      ...formFields,
      [name]: {
        ...formFields[name],
        invalid: false,
        validationHint: formFields[name].validation
          ? formFields[name].validation.hint
          : '',
      },
    });

  const handleModalConfirm = () => {
    if (!cardIssuing) {
      setCardIssuing(true);

      const customAddressFormFields = { ...formFields };
      delete customAddressFormFields.cardholder_name;

      const addressChanged = shipmentAddress !== CURRENT_ADDRESS;
      const newAddress = reduce(
        customAddressFormFields,
        (accumulator, { name, value }) => ({
          ...accumulator,
          [name]: value,
        }),
        {}
      );

      if (cardReplace) {
        onReplaceCard({
          virtualCardSelected,
          cardholderName: cardholderName.current,
          ...(addressChanged && {
            newAddress: {
              ...newAddress,
              country,
            },
          }),
          replaceReason: activeReplaceReason,
        });
      } else {
        onIssueCard({
          virtualCardSelected,
          cardholderName: cardholderName.current,
          ...(addressChanged && {
            newAddress: {
              ...newAddress,
              country,
            },
          }),
        });
      }
    }
  };

  const handleShipmentAddressChange = ({ target: { value } }) => {
    setShipmentAddress(value);

    setDisabled(!cardholderName.current || value === CUSTOM_ADDRESS);
  };

  const handleSelectedReasonChange = ({ target: { value } }) => {
    setActiveReplaceReason(value);
  };

  const customAddressFormFields = { ...formFields };
  delete customAddressFormFields.cardholder_name;

  const handleCardTypeSelection = ({ target: { value } }) =>
    setCardTypeSelected(value);

  return (
    <Modal
      modalInfo={{
        ...(cardReplace || expiringSoon
          ? { ...MODALS.REPLACE_CARD }
          : { ...MODALS.ISSUE_CARD }),
        disabled,
        content: (
          <IssueCardForm>
            <StyledSelectorGroup
              css={spacing({ bottom: 'mega' })}
              hideLabel
              value={cardTypeSelected}
              options={selectorGroupOptions}
              onChange={handleCardTypeSelection}
              label="Card type"
              noMargin
            />
            {cardReplace && !expiringSoon && (
              <CardReplaceContent>
                <Body noMargin variant="highlight">
                  Reason for the replacement:
                </Body>
                <RadioButtonGroup
                  options={replaceReasons}
                  hideLabel
                  onChange={handleSelectedReasonChange}
                  noMargin
                  value={activeReplaceReason}
                  label="Reason for the replacement"
                />
              </CardReplaceContent>
            )}
            <Input
              noMargin
              defaultValue={clientFullName}
              name={cardholderNameField.name}
              placeholder={cardholderNameField.placeholder}
              invalid={formFields[cardholderNameField.name].invalid}
              validationHint={
                formFields[cardholderNameField.name].validationHint ||
                formFields[cardholderNameField.name].validation.hint
              }
              label="Cardholder name"
              hideLabel
              onFocus={handleFocus}
              onBlur={handleCardholderNameBlur}
              onChange={handleCardholderNameChange}
            />
            {!virtualCardSelected && (
              <>
                <SubHeadline as="h3" noMargin>
                  Shipping address:
                </SubHeadline>
                <StyledRadioButtonGroup
                  value={shipmentAddress}
                  options={shipmentAddresses}
                  label="Select original or custom shipping address"
                  hideLabel
                  onChange={handleShipmentAddressChange}
                  noMargin
                />
                {customAddressOpen && (
                  <>
                    <CustomAddress>
                      {map(
                        customAddressFormFields,
                        ({
                          name,
                          placeholder,
                          invalid,
                          validation = {},
                          validationHint,
                        }) => (
                          <Input
                            noMargin
                            key={name}
                            name={name}
                            invalid={invalid}
                            placeholder={placeholder}
                            label={placeholder}
                            hideLabel
                            validationHint={validationHint || validation.hint}
                            onFocus={handleFocus}
                            onChange={handleCustomAddressChange}
                          />
                        )
                      )}
                    </CustomAddress>
                    <CountryIcon>
                      <svg>
                        <use href={href} />
                      </svg>
                      {COUNTRY_NAMES[country]}
                    </CountryIcon>
                  </>
                )}
              </>
            )}
          </IssueCardForm>
        ),
        onConfirm: handleModalConfirm,
      }}
      onClose={onClose}
    />
  );
};

IssueCardModal.defaultProps = {
  address: {},
  personalProfile: {},
  cardReplace: false,
  expiringSoon: false,
  onReplaceCard: () => {},
};

IssueCardModal.propTypes = {
  address: PropTypes.object,
  personalProfile: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onIssueCard: PropTypes.func.isRequired,
  onReplaceCard: PropTypes.func,
  cardReplace: PropTypes.bool,
  expiringSoon: PropTypes.bool,
};

export default IssueCardModal;
