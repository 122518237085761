import React from 'react';
import PropTypes from 'prop-types';
import { map, some, toLower, replace } from 'lodash';
import EmptyState from 'components/EmptyState';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CellFactory } from './factories/cell';
import SortableHeaderWrapper from '../../../SortableHeaderWrapper';
import { HIGHLIGHT_MERCHANTS } from '../../constants';

const Grid = styled('div')`
  ${({ theme: { colors, spacings, borderRadius } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: 20px 0 ${spacings.byte};
    background: ${colors.white};
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    border-radius: ${borderRadius.bit};
  `}
`;

const Row = styled('div')`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: row;
    color: ${colors.n900};
  `}
`;

const HeaderRow = styled(Row)`
  ${({ theme: { colors, borderWidth } }) => css`
    display: flex;
    border-bottom: ${borderWidth.kilo} solid ${colors.n200};
    min-width: 0px;
    word-break: break-word;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
  `}
`;

const ColumnTitle = styled('div')`
  ${({ theme: { spacings, borderWidth, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${spacings.tera};
    border-bottom: ${borderWidth.kilo} solid ${colors.n200};

    > p {
      margin: auto;
    }
  `}
`;

const SubColumnTitle = styled('div')(
  ({ theme: { spacings, colors } }) => css`
    display: flex;
    justify-content: center;
    flex-flow: row;
    height: 28px;
    min-width: 0px;
    padding: ${spacings.bit} ${spacings.byte};
    position: relative;
    align-items: center;
    color: ${colors.n700};
    white-space: nowrap;
  `
);

const SubColumnTitleContent = styled('span')`
  ${({ theme: { colors }, sorted, sortable }) => css`
    span {
      margin-right: 2px;

      ${sorted &&
      `
        cursor: pointer;
        color: ${colors.p500};
      `}

      ${sortable &&
      `
        &:hover {
          cursor: pointer;
          color: ${colors.p500};
        }
      `}
    }
  `}
`;

const HeaderWrapper = styled('div')`
  ${({ theme: { borderWidth, colors } }) => css`
    display: flex;
    flex-direction: row;

    & > div:not(:first-of-type) {
      border-left: ${borderWidth.kilo} solid ${colors.n200};
    }
  `}
`;

const BodyRowWrapper = styled('div')`
  ${({ matched, theme: { colors }, error }) => css`
    display: flex;
    flex-direction: row;
    &:hover {
      cursor: pointer;
      background: ${colors.b100};
      color: ${colors.bodyColor};
      ${matched &&
      `
        background: #E1FFEF;
        color: ${colors.bodyColor};
      `}

      * {
        opacity: 1;
      }
    }
    ${error &&
    `
    background: ${colors.r100};
      position: relative;
      &:hover {
        background: ${colors.r100};
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        background: ${colors.r500};
      }
    `}
  `}
`;

const BodyRowSection = styled('div')`
  ${({ theme: { borderWidth, colors } }) => css`
    display: flex;
    align-items: center;
    border-bottom: ${borderWidth.kilo} solid ${colors.n200};
    :not(:first-of-type) {
      border-left: ${borderWidth.kilo} solid ${colors.n200};
    }
  `}
`;

const BodyCell = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacings.byte} 0;
    align-items: center;
    word-break: break-word;
    padding: 0 ${theme.spacings.byte};
  `}
`;

const AuthorizationsGrid = ({
  columns,
  ignoreAuthorizationLoading,
  adjustAuthorizationLoading,
  authorizationsForAdjustment,
  onAdjustAuthorizationModal,
  onAdjustAuthorizationConfirm,
}) => (
  <Grid>
    <SortableHeaderWrapper
      columns={columns}
      HeaderWrapper={HeaderWrapper}
      HeaderRow={HeaderRow}
      ColumnTitle={ColumnTitle}
      SubColumnTitle={SubColumnTitle}
      SubColumnTitleContent={SubColumnTitleContent}
    />
    <div>
      {authorizationsForAdjustment.length ? (
        map(authorizationsForAdjustment, (authorization) => (
          <BodyRowWrapper
            key={`${authorization.authorization?.authorizationId}`}
            error={authorization.error}
          >
            {map(columns, ({ label, subColumns, style }, colIndex) => (
              <BodyRowSection
                key={`body-row-section-${colIndex}-${label}`}
                style={style}
              >
                {map(subColumns, ({ key, style: colStyle, fields = [] }) => (
                  <BodyCell key={key} style={colStyle}>
                    {map(fields, ({ name, getValue, ...props }) => (
                      <CellFactory
                        {...props}
                        key={`${key}-${name}`}
                        value={
                          getValue
                            ? getValue(authorization, name)
                            : authorization[name]
                        }
                        ignoreAuthorizationLoading={ignoreAuthorizationLoading}
                        adjustAuthorizationLoading={adjustAuthorizationLoading}
                        rowData={authorization}
                        fieldName={name}
                        onAdjustAuthorizationModal={onAdjustAuthorizationModal}
                        onAdjustAuthorizationConfirm={
                          onAdjustAuthorizationConfirm
                        }
                        style={{
                          backgroundColor: some(
                            HIGHLIGHT_MERCHANTS,
                            (partial) =>
                              toLower(
                                replace(
                                  getValue(authorization, name),
                                  /\s+/g,
                                  ' '
                                )
                              ).includes(partial)
                          )
                            ? 'orange'
                            : '',
                        }}
                      />
                    ))}
                  </BodyCell>
                ))}
              </BodyRowSection>
            ))}
          </BodyRowWrapper>
        ))
      ) : (
        <EmptyState text="No authorizations for adjustment" />
      )}
    </div>
  </Grid>
);

AuthorizationsGrid.defaultProps = {
  authorizationsForAdjustment: [],
};

AuthorizationsGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  ignoreAuthorizationLoading: PropTypes.array.isRequired,
  adjustAuthorizationLoading: PropTypes.array.isRequired,
  authorizationsForAdjustment: PropTypes.array,
  onAdjustAuthorizationModal: PropTypes.func.isRequired,
  onAdjustAuthorizationConfirm: PropTypes.func.isRequired,
};

export default AuthorizationsGrid;
