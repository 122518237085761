import React, { useContext } from 'react';
import ClientDataContext from 'context/clientData';
import { sumBy } from 'lodash/fp';
import { Body } from '@sumup/circuit-ui';
import formatBalance from 'services/formatBalance';
import { BalanceWrapper } from './TotalBalanceStyled';

const TotalBalance = () => {
  const {
    state: { clientData },
  } = useContext(ClientDataContext);

  if (!clientData.gasAccounts) {
    return null;
  }

  const totalBalance = () => {
    if (!clientData.balance) {
      return null;
    }

    const {
      balance: {
        balance: nonNegativeBalance,
        negative_balance: negativeBalance,
      },
      currency: { exponent, code: currencyCode },
    } = clientData;

    const mainBalance = negativeBalance * -1 + nonNegativeBalance;
    const subBalances = sumBy(
      (acc) => acc.balance?.negative_balance * -1 + acc.balance?.balance || 0,
      clientData.gasAccounts
    );

    return formatBalance({
      balance: mainBalance + subBalances,
      exponent,
      currencyCode,
    });
  };

  return (
    <>
      <BalanceWrapper size="two" noMargin>
        Total money in all balances:{' '}
        <Body size="two" variant="highlight" noMargin>
          {totalBalance() || '0.00'}
        </Body>
      </BalanceWrapper>
    </>
  );
};

export default TotalBalance;
